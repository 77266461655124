<template>
  <v-dialog v-model="confirmDialog" max-width="800">
    <v-card :loading="loading">
      <v-card-title class="headline">{{ $t("message.copy_event") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-menu v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" @input="menuDate = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-menu
              ref="menu"
              v-model="menuTime"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="time"
                  label="Time"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuTime"
                v-model="time"
                full-width
                format="24"
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              v-model="minutes"
              :rules="minutesRules"
              label="Minutes"
              prepend-icon="mdi-timer"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text @click="copy()">{{ $t("message.copy") }}</v-btn>
        <v-btn text @click="confirmDialog = false">{{ $t("message.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      date: new Date().toISOString().substr(0, 10),
      styledDate: null,
      dialog: false,
      lesson: {},
      lessonComments: {},
      lessonEquipment: {},
      places: [],
      types: [],
      confirmDialog: false,
      minutes: 0,
      menuDate: false,
      time: null,
      menuTime: false,
      minutesRules: [
        v => !!v || 'Minutes is required',
        v => /[0-9]+/.test(v) || 'Minutes must be valid'
      ]
    }
  },
  created () {
    bus.$on('openCopyEvent', (data) => {
      this.lesson = data.lesson

      this.date = new Date(data.lesson.DateTime).toISOString().substr(0, 10)
      this.time = new Date(data.lesson.DateTime).toISOString().substr(11, 5)
      this.minutes = data.lesson.Minutes

      this.customerList = data.customerList
      this.lessonComments = data.lessonComments
      this.lessonEquipment = data.lessonEquipment
      this.confirmCopyAction()
    })
  },
  beforeDestroy () {
    bus.$off('openCopyEvent', null)
  },
  methods: {
    confirmCopyAction () {
      this.confirmDialog = true
    },
    copy () {
      this.lesson.DateTime = this.date + "T" + this.time + ":00Z"
      this.lesson.Minutes = this.minutes
      this.lesson.CreationDate = new Date().toISOString()
      this.lesson.LastModifyDate = new Date().toISOString()
      this.loading = true
      httpClient
        .post('/event/api/v1/lessons/copy', this.lesson)
        //.post('http://localhost:13007/api/v1/lessons/copy', this.lesson)
        .then((r) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Lesson successfully copied'})
          this.lesson = r.data
          // Refresh parents
          bus.$emit('refreshLessons', true)
          bus.$emit('refreshCalendarLessons', {lesson: this.lesson})
          this.confirmDialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error copying lesson'})
        })
    },
  },
}
</script>