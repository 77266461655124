<template>
  <v-card class="my-2" :loading="loading">
    <v-card-title>
      <v-icon left>mdi-nas</v-icon> {{ $t("message.equipment") }} <EquipmentDialog :action="'create'" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        :label="$t('message.search')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="columns"
        :items="rows"
        :search="search"
        :items-per-page="10"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      >
        <template v-slot:item.Brand="{ item }">
          <span @click="openDialog(item)">{{ item.Brand }}</span>
        </template>
        <template v-slot:item.Model="{ item }">
          <span @click="openDialog(item)">{{ item.Model }}</span>
        </template>
        <template v-slot:item.Type="{ item }">
          <span @click="openDialog(item)">{{ item.Type }}</span>
        </template>
        <template v-slot:item.options="{ item }">
          <router-link v-bind:to="'/equipment/' + item.ID">
            <v-chip
              class="mx-1"
              color="blue lighten-5"
              text-color="black"
            >
              <v-avatar
                left
                class="blue darken-4"
                style="color: white !important"
              >
                <v-icon small>mdi-forward</v-icon>
              </v-avatar>
              Ver
            </v-chip>
          </router-link>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import EquipmentDialog from './EquipmentDialog'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      columns: [
      { text: 'Marca', align: 'left', value: 'Brand' },
      { text: 'Modelo', align: 'left', value: 'Model' },
      { text: 'Tipo', align: 'left', value: 'Type' },
      { text: this.$t('message.country'), sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25, 50]
    }
  },
  created: function () {
    bus.$on('refreshEquipments', () => { this.getAll() })
    this.getAll()
  },
  beforeDestroy () {
    bus.$off('refreshEquipments', null)
  },
  methods: {
    getAll: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/equipments')
        //.get('http://localhost:13011/api/v1/equipments')
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
        })
        .catch(() => { 
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting equipments'})
        })
    }
  },
  components: {
    EquipmentDialog
  }
}
</script>
