<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card :loading="loading">
      <v-card-title>{{ formatDate(lesson.DateTime) }}</v-card-title>
      <v-card-text>
        {{ $t("message.confirm_delete_message") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="deleteLesson()">{{ $t("message.confirm_delete") }}</v-btn>
        <v-btn color="primary" text @click="dialog = false">{{ $t("message.cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      lesson: {},
    }
  },
  created () {
    bus.$on('openLessonDeleteConfirm', (data) => {
      this.dialog = true
      this.lesson = data.lesson
    })
  },
  beforeDestroy () {
    bus.$off('openLessonDeleteConfirm', null)
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    deleteLesson: function () {
      this.loading = true
      httpClient
        .delete('/event/api/v1/lessons/' + this.lesson.ID)
        //.delete('http://localhost:13007/api/v1/lessons/' + this.lesson.ID)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: this.$t('message.event_deleted')})
          this.dialog = false
          if(this.$route.name === "Calendar") {
            bus.$emit("closeLessonDialogFullScreen")
            bus.$emit('refreshCalendarLessons', null)
          } else if(this.$route.path !== "/lesson") {
            this.$router.push('/lesson')
          } else {
            bus.$emit('refreshLessons')
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: this.$t('message.error')})
        })
    }
  }
}
</script>
