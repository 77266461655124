var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-room-service-outline")]),_vm._v(" Service Types "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":_vm.$t('message.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.rows,"search":_vm.search,"page":_vm.page,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"footer-props":{
        itemsPerPageOptions: _vm.rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}}}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }