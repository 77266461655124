<template>
  <span>
    <v-chip class="ma-2" color="blue lighten-5" text-color="black" @click="dialog = true">
      <v-avatar class="blue darken-4" style="color: white !important">
        <v-icon small>mdi-plus</v-icon>
      </v-avatar>
    </v-chip>
    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card v-if="addStaff" :loading="loading">
        <v-card-title><span v-if="isNew">Associate staff</span><span v-if="!isNew">Edit associated staff</span></v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-autocomplete v-model="lessonStaff.IDStaff" label="Lessons"
              :items="lessons"
              item-text="DateTime"
              item-value="ID"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" @click="createLessonStaff()">{{ $t("message.create") }}</v-btn>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="addLesson" :loading="loading">
        <v-card-title>Add to lesson</v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-autocomplete v-model="lessonStaff.IDStaff" label="Select staff"
              :items="staffList"
              item-text="Name"
              item-value="ID"
            ></v-autocomplete>
            <br />
            <v-text-field v-model="lessonStaff.Payment" label="Payment" value="0.00" suffix="€"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" @click="createLessonStaff()">{{ $t("message.create") }}</v-btn>
          <v-btn text @click="dialog = false">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    lessonID: Number,
    staffID: Number,
    addStaff: Boolean,
    addLesson: Boolean,
  },
  data: () => ({
    isNew: false,
    valid: false,
    loading: false,
    dialog: false,
    lessons: [],
    lessonStaff: {},
    staffList: [],
    st: [],
  }),
  watch: {
    dialog: function (val) {
      if(val) {
        this.lessonStaff.IDLesson = this.lessonID
        this.lessonStaff.IDStaff = this.staffID
        if(this.addStaff) {
          this.getLessons()
        }
        if(this.addLesson) {
          this.lessonStaff.Payment = ""
          this.getStaffs()
        }
      }
    },
  },
  methods: {
    getStaffs: function () {
      this.loading = true
      httpClient
        .get('/staff/api/v1/staffs')
        //.get('http://localhost:13007/api/v1/staffs')
        .then((r) => {
          this.loading = false
          this.staffList = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting staffs'})
        })
    },
    getLessons: function () {
      bus.$emit('loadingOn')
      httpClient
        .get('/event/api/v1/lessons')
        //.get('http://localhost:13007/api/v1/lessons')
        .then((r) => {
          this.loading = false
          this.lessons = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lessons'})
        })
    },
    createLessonStaff: function () {
      this.loading = true
      httpClient
        .post('/event/api/v1/lessonstaff', this.lessonStaff)
        //.post('http://localhost:13007/api/v1/lessonstaff', this.lessonStaff)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Staff added to lesson'})
          this.dialog = false
          bus.$emit('refreshLessonStaffs', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error adding staff to lesson'})
        })
    }
  }
}
</script>