<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="500">
      <template v-slot:activator="{ on }">
        <v-chip
          class="ma-1"
          color="blue lighten-5"
          text-color="black"
          v-on="on"
        >
          <v-avatar left
            class="blue darken-4"
            style="color: white !important"
          >
            <v-icon small>{{ btnIcon }}</v-icon>
          </v-avatar>
          <span>{{ btnText }}</span>
        </v-chip>
      </template>
      <v-card>
        <v-card-title><span v-if="isNew">Create transport</span><span v-if="!isNew">Change transport</span></v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-text-field
              v-if="isOldSmartSurf()"
              v-model="t.Name"
              :rules="nameRules"
              label="Nome"
              required
            ></v-text-field>
            <v-text-field
              v-if="isOldSmartSurf()"
              v-model="t.SeatNumber"
              :rules="nameRules"
              label="Número de lugares"
              required
            ></v-text-field>
            <v-text-field
              v-model="t.Brand"
              label="Marca"
            ></v-text-field>
            <v-text-field
              v-model="t.Model"
              label="Modelo"
            ></v-text-field>
            <v-text-field
              v-model="t.PlateNumber"
              label="Matrícula"
              required
            ></v-text-field>
            <v-text-field
              v-model="t.PlateDate"
              label="Data matrícula"
              required
            ></v-text-field>
            <v-text-field
              v-model="t.SerialNumber"
              label="Número de série"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" v-if="isNew" @click="create()">{{ $t("message.create") }}</v-btn>
          <v-btn color="primary" text :disabled="!valid" v-if="!isNew" @click="update()">{{ $t("message.update") }}</v-btn>
          <v-btn text @click="dialog = false">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    action: String,
    details: {}
  },
  data: () => ({
    t: {},
    dialog: false,
    isNew: true,
    valid: false,
    nameRules: [
      v => !!v || 'Name is required'
    ],
    seatNumberRules: [
      v => !!v || 'Seat number is required',
      v => /[0-9]+/.test(v) || 'Seat number must be valid'
    ]
  }),
  computed: {
    btnIcon: function () {
      if (this.action === 'update') { return 'mdi-pencil' } else { return 'mdi-plus' }
    },
    btnText: function () {
      if (this.action === 'update') { return 'Change' } else { return 'Create' }
    }
  },
  watch: {
    dialog: function (val) {
      if(val) {
        if(this.action === 'update') {
          this.isNew = false
          this.t = this.details
        }
      }
    },
  },
  methods: {
    isOldSmartSurf () {
      if(this.db !== "manuelpedrosousa") {
        return true
      }
      return false
    },
    openNewDialog: function () {
      this.t = {}
      this.isNew = true
      this.dialog = true
    },
    create: function () {
      this.t.SeatNumber = Number(this.t.SeatNumber)
      this.loading = true
      httpClient
        .post('/mix/api/v1/transports', this.t)
        //.post('http://localhost:13007/api/v1/transports', this.t)
        .then(() => {
          this.loading = false
          this.dialog = false
          this.transport = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Transporte criado com sucesso'})
          bus.$emit('refreshTransportList', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao criar transporte'})
        })
    },
    update: function () {
      this.t.SeatNumber = Number(this.t.SeatNumber)
      this.loading = true
      httpClient
        .put('/mix/api/v1/transport/' + this.t.ID, this.t)
        //.put('http://localhost:13007/api/v1/transport/' + this.t.ID, this.t)
        .then(() => {
          this.loading = false
          this.dialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Transporte atualizado com sucesso'})
          bus.$emit('refreshEquipment', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Erro ao actualizar transporte'})
        })
    }
  }
}
</script>