<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="400px">
    <template v-slot:activator="{ on }">
      <v-chip
        class="ma-1"
        color="blue lighten-5"
        text-color="black"
        v-on="on"
      >
        <v-avatar
          left
          class="blue darken-4"
          style="color: white !important"
        >
          <v-icon small>{{ btnIcon }}</v-icon>
        </v-avatar>
        <span>{{ btnText }}</span>
      </v-chip>
    </template>
      <v-card :loading="loading">
        <v-card-title><span v-if="isNew">Create place</span><span v-if="!isNew">Update place</span></v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <v-text-field
            v-model="place.Name"
            label="Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="place.Latitude"
            label="Latitude"
            required
          ></v-text-field>
          <v-text-field
            v-model="place.Longitude"
            label="Longitude"
            required
          ></v-text-field>
          <v-autocomplete
            v-model="place.IDCountry"
            :items="countries"
            item-text="Name"
            item-value="ID"
            label="Country"
            placeholder="Select country.."
          ></v-autocomplete>
          <v-textarea v-model="place.GoogleMaps" label="Google Maps" @keyup="getGoogleMapsPlace()"></v-textarea>
          <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
          >
            Find your place in <a href="https://www.google.com/maps/" target="_blanc">Google Maps</a> mark (right click "What's here?")
            it and copy the URL to here<br />
            <i>Video</i>
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="create()" v-if="isNew">{{ $t("message.create") }}</v-btn>
          <v-btn color="blue darken-1" text @click="update()" v-if="!isNew">{{ $t("message.update") }}</v-btn>
          <v-btn text @click="dialog = false">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    placeAction: String,
    placeDetails: {}
  },
  data: () => ({
    place: {},
    loading: false,
    dialog: false,
    isNew: true,
    valid: false,
    nameRules: [
      v => !!v || 'Name is required'
    ],
    seatNumberRules: [
      v => !!v || 'Sit number is required',
      v => /[0-9]+/.test(v) || 'Sit number must be valid'
    ],
    countries: [],
    googleMaps: "",
  }),
  computed: {
    btnIcon: function () {
      if (this.placeAction === 'update') {
        return 'mdi-pencil'
      } else {
        return 'mdi-plus'
      }
    },
    btnText: function () {
      if (this.placeAction === 'update') {
        return 'Edit'
      } else {
        return 'Create'
      }
    }
  },
  watch: {
    dialog: function (val) {
      if(val) {
        if (this.placeAction === 'update') {
          this.isNew = false
          this.place = this.placeDetails
        }
      }
      this.getCountries()
    },
  },
  methods: {
    getCountries() {
      httpClient
        .get('/mix/api/v1/countries')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting countries'})
        })
    },
    create () {
      this.loading = true
      httpClient
        .post('/place/api/v1/places', this.place)
        //.post('http://localhost:13007/api/v1/places', this.place)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Place created'})
          bus.$emit('refreshPlaceList')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating place'})
        })
    },
    update () {
      this.loading = true
      httpClient
        .put('/place/api/v1/places/' + this.place.ID, this.place)
        //.put('http://localhost:13007/api/v1/places/' + this.place.ID, this.place)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Place updated'})
          bus.$emit('refreshPlaceList')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating place'})
        })
    },
    getGoogleMapsPlace: function () {
      if(this.place.GoogleMaps.indexOf("www.google.com") !== -1 && this.place.GoogleMaps.indexOf("maps") !== -1) {
        this.place.GoogleMaps.split("/").forEach(part => {
          if( part.indexOf("@") !== -1 ) {
            let placeCoords = part.split(",")
            this.place.Latitude = placeCoords[0].replace("@", "")
            this.place.Longitude = placeCoords[1]
          }
        })
      }
    }
  }
}
</script>