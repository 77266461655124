<template>
  <span>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card :loading="loading">
        <v-card-title v-if="studentName">{{ studentName }} - {{ studentEmail }}</v-card-title>
        <v-card-title v-if="!studentName">Credits</v-card-title>
        <v-card-text>
          <v-row align="center" dense>
            <v-col cols="12" sm="8" md="9" lg="9">
              <v-select
                v-model="lessonStudent.IDPackage"
                :items="packageList"
                item-text="Name"
                item-value="IDStudentPackage"
                item-disabled="Consumed"
                label="Package"
                required
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="3">
              <StudentPackageAdd :studentID="lessonStudent.IDStudent" :buttonLable="'New Package'" />
            </v-col>
          </v-row>
          <v-row align="center" dense>
            <v-col cols="12" sm="8" md="9" lg="9">
              <v-select
                v-model="lessonStudent.IDService"
                :items="serviceList"
                item-text="Name"
                item-value="IDStudentService"
                item-disabled="Consumed"
                label="Service"
                required
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="3">
              <StudentServiceAdd :studentID="lessonStudent.IDStudent" :buttonLable="'New Service'" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createLessonStudentPackage()">{{ $t("message.update") }}</v-btn>
          <v-btn color="darken-1" text @click="closeDialog()">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import StudentPackageAdd from '../Student/StudentPackageAdd'
import StudentServiceAdd from '../Student/StudentServiceAdd'

export default {
  data: () => ({
    loading: false,
    dialog: false,
    studentName: "",
    studentEmail: "",
    lessonStudent: {},
    packageList: [],
    serviceList: [],
    studentPckCreated: {},
    studentSrvCreated: {},
  }),
  created() {
    bus.$on('openFormPackageServiceDialog', (data) => {
      this.studentName = data.studentName
      this.lessonStudent.IDStudent = data.idStudent
      this.lessonStudent.IDLesson = data.idLesson
      this.getLessonStudent()
      this.dialog = true
    })

    bus.$on('refreshFormPackageServicePackages', (data) => {
      this.studentPckCreated = data.package
      this.getStudentPackages()
    })
    bus.$on('refreshFormPackageServiceServices', (data) => {
      this.studentSrvCreated = data.service
      this.getStudentServices()
    })
  },
  beforeDestroy () {
    bus.$off('openFormPackageServiceDialog', null)
    bus.$off('refreshFormPackageServicePackages', null)
    bus.$off('refreshFormPackageServiceServices', null)
  },
  methods: {
    getLessonStudent () {
      this.loading = true
      httpClient
        .get('/event/api/v1/lessonstudent/' + this.lessonStudent.IDLesson + '/' + this.lessonStudent.IDStudent)
        //.get('http://localhost:13007/api/v1/lessonstudent/' + this.lessonStudent.IDLesson + '/' + this.lessonStudent.IDStudent)
        .then((resp) => {
          this.loading = false
          if(resp) {
            this.studentPckCreated.ID = resp.data.IDPackage
            this.studentSrvCreated.ID = resp.data.IDService

            // Just after getting the student package and service id we do the rest
            this.getStudentPackages()
            this.getStudentServices()
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson stundent information'})
        })
    },
    getStudentPackages () {
      this.loading = true
      httpClient
        .get('/package/api/v1/packages/student/' + this.lessonStudent.IDStudent)
        .then((resp) => {
          this.loading = false
          if(this.studentPckCreated !== undefined) {
            this.lessonStudent.IDPackage = this.studentPckCreated.ID
          }

          if(resp) {
            this.packageList = resp.data.filter(pck => pck.Consumed !== true || pck.IDStudentPackage === this.lessonStudent.IDPackage)
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating student packages'})
        })
    },
    getStudentServices () {
      this.loading = true
      httpClient
        .get('/package/api/v1/services/student/' + this.lessonStudent.IDStudent)
        .then((resp) => {
          this.loading = false
          if(this.studentSrvCreated !== undefined) {
            this.lessonStudent.IDService = this.studentSrvCreated.ID
          }

          if(resp) {
            this.serviceList = resp.data.filter(srv => srv.Consumed !== true || srv.IDStudentService === this.lessonStudent.IDService)
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting student services'})
        })
    },
    createLessonStudentPackage () {
      if(this.lessonStudent.IDPackage === undefined) {
        this.lessonStudent.IDPackage = 0
      }
      if(this.lessonStudent.IDService === undefined) {
        this.lessonStudent.IDService = 0
      }
      this.loading = true
      httpClient
        .put('/event/api/v1/lessonstudent', this.lessonStudent)
        //.put('http://localhost:13007/api/v1/lessonstudent', this.lessonStudent)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package/Service added to the lesson'})
          bus.$emit('refreshStudentLessons')
          bus.$emit('refreshStudentServices')
          bus.$emit('refreshStudentPackages')
          bus.$emit('refreshLessonStudentList') // For TableStudent component
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error associating student package to the lesson'})
        })
    },
    closeDialog() {
      this.studentName = ""
      this.lessonStudent = {}
      this.studentPckCreated = {}
      this.studentSrvCreated = {}
      this.dialog = false
    }
  },
  components: {
    StudentPackageAdd,
    StudentServiceAdd
  }
}
</script>