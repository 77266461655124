<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-home-city-outline</v-icon> Rooms <RoomDialog  :roomAction="'create'"/>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        :label="$t('message.search')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="columns"
        :items="rows"
        :search="search"
        :items-per-page="10"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      >
        <template v-slot:item.State="{ item }">
          <v-chip
            v-if="item.State == 'Active'"
            class="ma-0"
            color="green"
            text-color="white"
          >
            {{ item.State }}
          </v-chip>
          <v-chip
            v-if="item.State == 'Inactive'"
            class="ma-0"
            color="red"
            text-color="white"
          >
            {{ item.State }}
          </v-chip>
        </template>
        <template v-slot:item.options="{ item }">
          <router-link v-bind:to="'/room/' + item.ID">
            <v-chip class="mx-1" color="blue lighten-5" text-color="black">
              <v-avatar left class="blue darken-4" style="color: white !important">
                <v-icon small>mdi-forward</v-icon>
              </v-avatar>
              View
            </v-chip>
          </router-link>
          <!--<v-btn icon @click="confirmDelete(item)">
            <v-icon color="info">mdi-delete</v-icon>
          </v-btn>-->
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import RoomDialog from './RoomDialog'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      rowsPerPageItems: [5, 10, 25, 50],
      columns: [
        { text: this.$t('message.name'), align: 'left', value: 'Name' },
        { text: 'Bed Number', align: 'left', value: 'BedNumber' },
        { text: 'State', value: 'State' },
        { text: '', sortable: false, value: 'options' }
      ],
    }
  },
  created () {
    this.getAll()
    bus.$on('refreshRoomList', () => { this.getAll() })
  },
  beforeDestroy () {
    bus.$off('refreshRoomList', null)
  },
  methods: {
    getAll: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/rooms')
        //.get('http://localhost:13007/api/v1/rooms')
        .then((resp) => {
          this.loading = false
          this.rows = []
          if (resp.data !== null) {
            this.rows = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting rooms'})
        })
    }
  },
  components: {
    RoomDialog,
  }
}
</script>
