<template>
  <div>
    <v-dialog v-model="dialog" max-width="400px">
      <v-card
        :loading="loading"
        class="mx-auto elevation-1">
        <v-img 
          class="white--text align-end"
          src="@/assets/praia_ondas.jpg">
          <v-card-title
            class="justify-end">
            <img style="height: 50px;" src="@/assets/logo.png" />
          </v-card-title>
        </v-img>
        <v-card-title>Login</v-card-title>
        <v-card-text v-if="!usePhone">
          <v-form>
            <v-text-field
              v-model="user.email"
              label="Email"
              name="nm-smart-login"
              prepend-icon="mdi-account"
              type="text"
            ></v-text-field>
            <v-text-field
              v-if="usePhone"
              v-model="user.phone"
              label="Phone (Country code needed +351 / 00351)"
              name="nm-smart-phone-new"
              prepend-icon="mdi-phone"
              type="text"
            ></v-text-field>
            <v-text-field
              ref="inputPass"
              v-model="user.password"
              id="password"
              label="Password"
              name="nm-smart-password"
              prepend-icon="mdi-lock"
              type="password"
              v-on:keyup.enter="login"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text v-if="usePhone">
          <v-form>
            <v-text-field
              v-if="usePhone"
              v-model="user.phone"
              label="Phone (Country code needed +351 / 00351)"
              name="nm-smart-phone-new"
              prepend-icon="mdi-phone"
              type="text"
            ></v-text-field>
            <v-text-field
              v-model="user.token"
              id="token"
              label="Token"
              name="nm-smart-token"
              prepend-icon="mdi-key-variant"
              type="number"
              v-on:keyup.enter="login"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text v-if="message">
          <span class="red--text">{{ message }}</span>
        </v-card-text>
        <v-card-actions>
          <v-btn text class="loginBtn" color="warning" @click="signup">Register</v-btn>
          <v-spacer></v-spacer>
          <v-btn text class="loginBtn" color="primary" @click="login">Login</v-btn>
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      usePhone: false,
      user: {},
      message: "",
    }
  },
  created () {
    if(this.$route.query.type === "token") {
      this.usePhone = true
    }
    bus.$on("loginDialog", () => this.dialog = true)
    bus.$on("loginRegisteredUser", (data) => {
      this.user.email = data.email
      setTimeout(() => {
        this.focusInputPass()
      }, 1000)
    })
  },
  beforeDestroy () {
    bus.$off('loginDialog', null)
  },
  methods: {
    login() {
      this.loading = true
      httpClient
        .post('/oauth/api/v1/signin', this.user)
        //.post('http://localhost:13000/api/v1/signin', this.user)
        .then((resp) => {
          this.loading = false
          this.$store.commit('setUserName', resp.data.name)
          this.$store.commit('setEmail', resp.data.email)
          this.$store.commit('setProfile', resp.data.profile)

          bus.$emit('loggedIn')
          this.$forceUpdate()
          this.dialog = false

          this.$router.push({ name: 'User', query: { redirect: '/user' } })
        })
        .catch(() => {
          this.loading = false
          this.message = "Wrong email/password"
          bus.$emit('snackBarMsg', {error: true, msg: 'Wrong email/password'})
        })
    },
    signup() {
      bus.$emit("signupDialog")
      this.dialog = false
    },
    focusInputPass() {
      // Set the focus on the input element when the button is clicked
      this.$refs.inputPass.focus();
    },
  }
}
</script>
