<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="mx-auto" :loading="loading">
      <v-card-title><span v-if="isNew">Create lesson type</span><span v-if="!isNew">Update lesson type</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row dense>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field label="Name" :rules="nameRules" v-model="lessonType.Name" autocomplete="nope"/>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field label="Description" :rules="descRules" v-model="lessonType.Description" autocomplete="nope"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" text :disabled="!valid" v-if="isNew" @click="create()">{{ $t("message.create") }}</v-btn>
        <v-btn color="info" text :disabled="!valid" v-if="!isNew" @click="update()">{{ $t("message.update") }}</v-btn>
        <v-btn color="darken-1" text @click="closeDialog()">{{ $t("message.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      isNew: true,
      lessonType: {},
      error: '',
      dialog: false,
      valid: false,
      nameRules: [
        v => !!v || 'Name is required'
      ],
      descRules: [
        v => !!v || 'Description is required'
      ],
    }
  },
  created() {
    bus.$on('openLessonTypeDialog', (e) => { 
      this.dialog = true
      if(e.lessonType.ID !== undefined) {
        this.isNew = false
        this.lessonType = e.lessonType
      } else {
        this.isNew = true
      }
    })
  },
  beforeDestroy () {
    bus.$off('openLessonTypeDialog', null)
  },
  methods: {
    create () {
      this.loading = true
      httpClient
        .post('/event/api/v1/lessontypes', this.lessonType)
        //.post('http://localhost:13007/api/v1/lessons', this.lessonType)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Lesson type successfully created'})
          bus.$emit('refreshLessonTypes')
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating lesson type'})
        })
    },
    update() {
      this.loading = true
      httpClient
        .put('/event/api/v1/lessontypes/' + this.lessonType.ID, this.lessonType)
        //.put('http://localhost:13007/api/v1/lessontypes/' + this.lessonType.ID, this.lessonType)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Lesson type successfully updated'})
          bus.$emit('refreshLessonTypes', true)
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating lesson type'})
        })
    },
    closeDialog() {
      this.lessonType = {}
      this.dialog = false
    }
  }
}
</script>