<template>
  <div style="min-width: 310px">
    <highcharts :options="chartOptions" :updateArgs="updateArgs"></highcharts>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import { Chart } from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart
  },
  data () {
    const inLabel = this.$t('message.in');
    const earningsLabel = this.$t('message.earnings');
    return {
      yearSelected: new Date().getFullYear(),
      monthSelected: 0,
      chartOptions: {
        chart: {
          type: 'line'
        },
        title: {
          text: ""
        },
        xAxis: {
          title: {
            text: ''
          },
          gridLineWidth: 0,
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          },
          formatter: function() {
            return '€' + this.value;
          }
        },
        tooltip: {
          backgroundColor: '#FCFFC5',
          borderColor: 'black',
          borderRadius: 10,
          borderWidth: 3,
          formatter: function() {
            if(this.series.name === earningsLabel) {
              return '<b>' + this.y + '</b>€ ' + inLabel + ' <b>' + this.x + '</b>'
            }
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true
          }
        },
        responsive: {  
          rules: [{  
            condition: {  
              maxWidth: 500  
            },  
            chartOptions: {
              legend: {
                enabled: false
              }
            }
          }]
        },
        series: [],
      },
      updateArgs: [true, true, { duration: 1000 }]
    }
  },
  mounted () {
    this.get()
    document.getElementsByClassName('highcharts-credits')[0].remove()
  },
  created() {
    bus.$on("ChartsMonth", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
      this.get()
    })
    bus.$on("ChartsYear", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
      this.get()
    })
  },
  methods: {
    getAll() {
      this.yearSelected = 0
      this.get()
    },
    get() {
      let url = 'mix/api/v1/reports/earnings';
      //let url = 'http://localhost:13007/api/v1/reports/earnings';

      const params = {};
      if (this.yearSelected) {
        params.year = this.yearSelected;
      }
      if (this.monthSelected) {
        params.month = this.monthSelected;
      }

      httpClient
        .get(url, { params })
        .then((resp) => {
          let days = []
          let years = []
          let payments = {
            name: this.$t('message.earnings'),
            type: 'bar',
            color: '#00ACC1',
            data: []
          }

          resp.data.forEach(e => {
            const totalPayment = Number(e.TotalPayment);
            payments.data.push(totalPayment)
            years.push(e.Year)
            days.push(e.Day)
          })

          this.chartOptions.series = []

          this.chartOptions.xAxis.title.text = 'Years'
          this.chartOptions.xAxis.categories = years

          if(this.yearSelected !== 0) {
            this.chartOptions.xAxis.title.text = 'Months'
            this.chartOptions.xAxis.categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          }

          if(this.monthSelected !== 0) {
            this.chartOptions.xAxis.title.text = 'Days'
            this.chartOptions.xAxis.categories = days
          }

          this.chartOptions.series.push(payments);
        })
        .catch(() => { 
          bus.$emit('snackBarMsg', {error: true, msg: this.$t('error')})
        })
    }
  }
}
</script>