<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <v-card :loading="loading">
      <v-card-title><span v-if="isNew">Associate service</span><span v-if="!isNew">Edit associated service</span></v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-2">
        <v-autocomplete v-model="selectedService.IDService" @change="checkServicePrice()"
          label="Services" :items="availableServices" item-text="Name" item-value="ID"></v-autocomplete>
        <v-text-field type="number" v-model="selectedService.Price" label="Price" required disabled="disabled"></v-text-field>
        <v-text-field type="number" v-model="selectedService.Quantity" label="Quantity" required ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="associate()" v-if="isNew">Add</v-btn>
        <v-btn color="blue darken-1" text @click="associated()" v-if="!isNew">{{ $t("message.update") }}</v-btn>
        <v-btn color="darken-1" text @click="dialog = false">{{ $t("message.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      isNew: false,
      pck: {},
      selectedService: {},
      availableServices: []
    }
  },
  created () {
    bus.$on('openAssociateServiceDialog', (data) => {
      this.getServices()
      this.dialog = true
      if(!('IDService' in data.srv)) {
        this.isNew = true
      }
      this.selectedService = data.srv
      this.pck = data.pck
    })
  },
  beforeDestroy () {
    bus.$off('openAssociateServiceDialog', null)
  },
  methods: {
    checkServicePrice () {
      this.availableServices.forEach(avbSrv => {
        if (avbSrv.ID == this.selectedService.IDService) {
          this.selectedService.Price = avbSrv.Price
        }
      });
    },
    getServices () {
      this.loading = true
      httpClient
        .get('/package/api/v1/services')
        //.get('http://localhost:13007/api/v1/services')
        .then((response) => {
          this.loading = false
          this.availableServices = response.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting available services'})
        })
    },
    associate () {
      this.loading = true
      this.selectedService.IDPackage = this.pck.ID
      this.selectedService.Price = parseInt(this.selectedService.Price)
      this.selectedService.Quantity = parseInt(this.selectedService.Quantity)

      if (this.selectedService.Quantity === 0) {
        bus.$emit('snackBarMsg', {error: true, msg: 'The service quantity must be greater than 0 to be associated with the package.'})
        this.loading = false
        return
      }

      httpClient
        .post('/package/api/v1/package/service', this.selectedService)
        //.post('http://localhost:13007/api/v1/package/service', this.selectedService)
        .then(() => {
          this.loading = false
          this.dialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service associated to package'})
          bus.$emit('packageView', this.pck)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error associating service to package'})
        })
    },
    associated () {
      this.loading = true
      this.selectedService.IDPackage = this.pck.ID
      this.selectedService.Price = parseInt(this.selectedService.Price)
      this.selectedService.Quantity = parseInt(this.selectedService.Quantity)
      httpClient
        .put('/package/api/v1/packages/service', this.selectedService)
        //.put('http://localhost:13007/api/v1/packages/service', this.selectedService)
        .then(() => {
          this.loading = false
          this.dialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service package relation updated'})
          bus.$emit('packageView', this.pck)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating service package relation'})
        })
    }
  }
}
</script>