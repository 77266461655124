<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :loading="loading">
      <v-card-title class="headline">{{ $t("message.invite_user") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form v-model="isValid">
              <v-text-field
                v-model="newUserEmail"
                :rules="newUserEmailRules"
                :label="this.$t('message.email')"
                prepend-icon="mdi-email"
                required
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" :disabled="!isValid" text @click="addAppUser()">{{ $t("message.invite") }}</v-btn>
        <v-btn text @click="dialog = false">{{ $t("message.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      dialog: false,
      isValid: false,
      newUserEmail: "",
      newUserEmailRules: [
        v => !!v || 'Email is required',
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Email must be valid'
      ]
    }
  },
  created () {
    bus.$on('openAddAppUser', () => {
      this.dialog = true
    })
  },
  methods: {
    openAddNewUser () {
      this.dialog = true
    },
    addAppUser() {
      if (this.newUserEmail !== "") {
        console.log("Inviting user..")
      }
    }
  },
}
</script>