<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-train-car</v-icon> Transports <TransportDialog />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        :label="$t('message.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.options="{ item }">
        <router-link v-bind:to="'/transport/' + item.ID">
          <v-chip
            class="mx-1"
            color="blue lighten-5"
            text-color="black"
          >
            <v-avatar
              left
              class="blue darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
            View
          </v-chip>
        </router-link>
        <!--<v-btn icon @click="openDialog(item)">
          <v-icon color="info">mdi-pencil</v-icon>
        </v-btn>-->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import TransportDialog from './TransportDialog'

export default {
  props: {
    customerID: Number
  },
  data () {
    return {
      loading: false,
      search: '',
      rowsPerPageItems: [5, 10, 25, 50],
      rows: [],
      columns: [
        { text: 'Marca', align: 'left', value: 'Brand' },
        { text: 'Modelo', align: 'left', value: 'Model' },
        { text: 'Matrícula', align: 'left', value: 'PlateNumber' },
        { text: 'Data matrícula', align: 'left', value: 'PlateDate' },
        { text: this.$t('message.options'), sortable: false, value: 'options' }
      ],
    }
  },
  mounted: function () {
    if(this.db !== "manuelpedrosousa") {
      this.columns = [
        { text: this.$t('message.name'), align: 'left', value: 'Name' },
        { text: 'Seat Number', align: 'left', value: 'SeatNumber' },
        { text: this.$t('message.options'), sortable: false, value: 'options' }
      ]
    }
  },
  created () {
    this.getAll()
    bus.$on('refreshTransportList', () => this.getAll())
  },
  methods: {
    getAll() {
      this.loading = true
      httpClient
        .get('/mix/api/v1/transports')
        //.get('http://localhost:13007/api/v1/transports')
        .then((resp) => {
          this.loading = false
          this.rows = []
          if (resp.data !== null) {
            this.rows = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting transports'})
        })
    },
    openDialog(transport) {
      bus.$emit('transportDialog', {transport: transport, isNew: false})
    },
  },
  components: {
    TransportDialog
  }
}
</script>
