<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-nas</v-icon> <span>{{ this.item.Name }}</span></div>
            <v-spacer></v-spacer>
            <ItemDialog :action="'update'" :details="item" />
            <v-chip  @click="backToList()"
              class="ma-1"
              color="blue lighten-5"
              text-color="black"
            >
              <v-avatar left
                class="blue darken-4"
                style="color: white !important"
              >
                <v-icon small>mdi-keyboard-backspace</v-icon>
              </v-avatar>
              <span>{{ $t("message.back_to_list") }}</span>
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3" lg="3">
                {{ $t("message.quantity") }}: <span>{{ this.item.Quantity }}</span><br />
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                {{ $t("message.type") }}: <span>{{ this.item.Type }}</span><br />
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                Usage: <span>{{ this.item.totalUsage }} time(s)</span><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import ItemDialog from './ItemDialog'

export default {
  data () {
    return {
      item: {},
      loading: false
    }
  },
  created () {
    this.item.ID = +this.$route.params.id
    this.get()
    bus.$on('refreshItemView', () => {
      this.get()
    })
  },
  methods: {
    get () {
      this.loading = true
      httpClient
        .get('mix/api/v1/item/' + this.item.ID)
        //.get('http://localhost:13007/api/v1/item/' + this.item.ID)
        .then((r) => {
          this.loading = false
          this.item = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting item'})
        })
    },
    backToList () {
      this.$router.push('/item')
    }
  },
  components: {
    ItemDialog
  }
}
</script>