import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const getYearList = (startYear) => {
  const currentYear = new Date().getFullYear()
  const yearList = [{id: 0, name: 'None'}]

  for (let year = currentYear; year >= startYear; year--) {
    yearList.push({ id: year, name: year.toString() })
  }

  return yearList
}

export default new Vuex.Store({
  state: {
    yearList: [],
    monthList: [
      {"id": 0, "name": "None"},
      {"id": 1, "name": "January"}, {"id": 2, "name": "February"}, {"id": 3, "name": "March"},
      {"id": 4, "name": "April"}, {"id": 5, "name": "May"}, {"id": 6, "name": "June"},
      {"id": 7, "name": "July"}, {"id": 8, "name": "August"}, {"id": 9, "name": "September"},
      {"id": 10, "name": "October"}, {"id": 11, "name": "November"}, {"id": 12, "name": "December"},
    ],
    user: {},
    userName: "",
    email: "",
    token: null,
    profile: null,
    isAdmin: false,
    isMaster: false,
    history: [],
    calendar: {
      type: 'week',
      start: null,
      end: null,
    },
    filters: {
      student: {
        list: ''
      },
      lesson: {
        list: ''
      },
      customer: {
        list: ''
      },
      event: {
        list: ''
      },
      work: {
        list: ''
      },
    }
  },
  mutations: {
    setYearList(state, yearList) {
      state.yearList = yearList
    },
    setUserName (state, value) {
      state.userName = value
    },
    setEmail (state, value) {
      state.email = value
    },
    setProfile (state, value) {
      state.profile = value
    },
    add (state, value) { 
      if (state.history.length >= 6) {
        state.history.shift()
      }
      state.history.push(value)
    },
    calendarType (state, value) {
      state.calendar.type = value
    },
    calendarStart (state, value) {
      state.calendar.start = value
    },
    calendarEnd (state, value) {
      state.calendar.end = value
    },
    filtersStudentList (state, value) {
      state.filters.student.list = value
    },
    filtersLessonList (state, value) {
      state.filters.lesson.list = value
    },
    filtersWorkList (state, value) {
      state.filters.work.list = value
    },
  },
  actions: {
    async fetchYearList({ commit }) {
      try {
        // TODO: Create API to retrieve the first year
        //const response = await fetch('/api/getFirstYear')
        //const data = await response.json()
        //const firstYear = data.firstYear

        const firstYear = 2015

        const yearList = getYearList(firstYear)

        commit('setYearList', yearList)
      } catch (error) {
        console.error('Failed to fetch year list:', error)
      }
    }
  },
  plugins: [createPersistedState({
    paths: ['userName', 'email', 'profile']
  })],
})