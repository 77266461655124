<template>
  <div>
    <v-toolbar>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-btn color="blue" icon v-on="on"
          ><v-icon>mdi-view-dashboard</v-icon></v-btn>
        </template>
        <v-date-picker v-model="date" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$refs.dialog.save(date);dayChange()">OK</v-btn>
          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
        </v-date-picker>
      </v-dialog>
      <v-toolbar-title>{{ this.styledDate }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <gb-flag code="pt" size="small" />
      <v-toolbar-items style="height: unset">
        <LessonDialog />
      </v-toolbar-items>
    </v-toolbar>
    <br />

    <v-card :loading="loading">
      <v-responsive>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <!-- https://alligator.io/vuejs/vue-google-maps/ -->
              <gmap-map
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false
                }"
                :center="center"
                :zoom="zoom"
                :map-type-id="mapType"
                style="width:100%; height: 350px;"
              > <!-- icon="https://vue.nelsonjvf.com/favicon.ico" -->
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  @click="openPanel(m)"
                ></gmap-marker>
              </gmap-map>

              <WorkViewFullDialog />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-card outlined :loading="loading">
                <v-timeline v-if="isMobile()" dense align-top>
                  <v-timeline-item
                    v-for="item in items"
                    :key="item.id"
                    :color="item.color"
                    :hide-dot="item.hidedot"
                    small
                  >
                    <div v-if="item.type === 'day'"><h1>{{ getPrettyDate(item.date) }}</h1></div>
                    <div v-if="item.type === 'lesson'">
                      <h2 :class="`headline font-weight-light mb-4 orange--text`">
                        {{getTime(item.lesson.DateTime)}}
                      </h2>
                      <div>
                        <h3 style="font-weight: bold">{{ item.lesson.Place }} <span v-if="item.lesson.Type"> - {{ item.lesson.Type }}</span></h3>
                        <h5>{{ $t("message.comments") }}</h5>
                        <p>{{ item.lesson.Comments }}</p>
                        <h5><span v-if="item.lesson.Staffs">{{ item.lesson.Staffs.length }}</span> {{ $t("message.instructors") }}</h5>
                        <p v-for="s in item.lesson.Staffs" :key="s.ID" style="margin: 0px; padding-left: 5px;font-size: 13px; margin-bottom: 0px">{{ s.Name }} <span v-if="s.Phone">- <a :href="`tel:s.Phone`">{{ s.Phone }}</a></span></p>
                        <h5 style="padding-top: 10px"><span v-if="item.lesson.Students">{{ item.lesson.Students.length }}</span> {{ $t("message.customers") }}</h5>
                        <p v-for="s in item.lesson.Students" :key="s.ID" style="font-size: 13px; margin-bottom: 0px">
                          <span>{{ s.Name }}</span> <span v-if="s.Phone">- <a :href="`tel:s.Phone`">{{ s.Phone }}</a></span><br />
                          <span v-if="s.Board"><v-chip class="ma-1" color="orange" text-color="white" x-small>{{ $t("message.board") }}</v-chip>{{s.Board}}</span><br/>
                          <span v-if="s.Wetsuit"><v-chip class="ma-1" color="orange" text-color="white" x-small>{{ $t("message.wetsuit") }}</v-chip>{{s.Wetsuit}}</span><br/>
                          <span v-if="s.Transport"><v-chip class="ma-1" color="green" text-color="white" x-small>{{ $t("message.transport") }}</v-chip>{{s.Transport}}</span><br/>
                        </p>
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>

                <v-card class="my-3" v-if="!isMobile()" elevation="0">
                  <v-card-title>{{ $t("message.today_events") }}</v-card-title>
                  <v-card-text>
                    <v-timeline>
                      <v-timeline-item
                        v-for="item in items"
                        :key="item.id"
                        color="blue"
                        small
                      >
                        <div v-if="item.type === 'day'"><h1>{{ getPrettyDate(item.date) }}</h1></div>
                        <template v-slot:opposite v-if="item.type === 'lesson'">
                          <span
                            class="headline font-weight-bold blue--text"
                            v-text="getTime(item.lesson.DateTime)"
                          ></span>
                        </template>
                        <v-card class="elevation-2" v-if="item.type === 'lesson'">
                          <v-card-title class="headline">{{ item.lesson.Place }}</v-card-title>
                          <v-card-subtitle v-if="item.lesson.Type">{{ item.lesson.Type }}</v-card-subtitle>
                          <v-card-text>
                            <h4>{{ $t("message.comments") }}</h4>
                            <p>{{ item.lesson.Comments }}</p>
                            <h4><span v-if="item.lesson.Staffs">{{ item.lesson.Staffs.length }}</span> {{ $t("message.instructors") }}</h4>
                            <p v-for="s in item.lesson.Staffs" :key="s.ID" style="margin: 0px; padding-left: 5px">{{ s.Name }}</p>
                            <h4 style="padding-top: 10px" v-if="item.lesson.Students">{{ item.lesson.Students.length }} {{ $t("message.customers") }}</h4>
                            <p v-for="s in item.lesson.Students" :key="s.ID">
                              <span>{{ s.Name }}</span> <span v-if="s.Phone">- <a :href="`tel:s.Phone`">{{ s.Phone }}</a> <br/></span>
                              <!--<span>{{ s.Package }}</span> <span>{{ s.Service }}</span><br />-->
                              <span v-if="s.Board"><v-chip class="ma-1" color="orange" text-color="white" x-small>{{ $t("message.board") }}</v-chip>{{s.Board}} <br/></span>
                              <span v-if="s.Wetsuit"><v-chip class="ma-1" color="orange" text-color="white" x-small>{{ $t("message.wetsuit") }}</v-chip>{{s.Wetsuit}} <br/></span>
                              <span v-if="s.Transport"><v-chip class="ma-1" color="green" text-color="white" x-small>{{ $t("message.transport") }}</v-chip>{{s.Transport}} <br/></span>
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-responsive>
    </v-card>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'
import LessonDialog from '../Lesson/LessonDialog'
import WorkViewFullDialog from '../Work/WorkViewFullDialog'

export default {
  data() {
    return {
      loading: false,
      loadingDaily: false,
      markers: [],
      lessons: [],
      items: [],
      groupedDataBoardsWetsuits: {}, 
      boardTotals: {},
      wetsuitTotals: {},
      modal: false,
      date: new Date().toISOString().substr(0, 10),
      styledDate: '',
      center: { lat: 39.4922359, lng: -9.0507655 },
      zoom: 6,
      mapType: 'terrain',
    };
  },
  created () {
    this.getTodayLessons()
    bus.$on('refreshLessons', () => { this.getTodayLessons() })
    
    //this.center = { lat: 38.7356805, lng: -9.4400442 } // Surf Ville
    this.center = { lat: 38.73273550296528, lng: -9.288985545363454 }
    this.zoom = 11
    this.mapType = 'roadmap'
  },
  beforeDestroy () {
    bus.$off('refreshLessons', null)
  },
  methods: {
    styleDate() {
      if(this.isMobile()) {
        this.styledDate = moment.parseZone(this.date).format('DD-MM-YYYY')
        return
      }
      this.styledDate = moment.parseZone(this.date).format('dddd, D MMMM YYYY')
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      
      return false
    },
    openPanel: function () {
      bus.$emit('openEventFullDialog', {isNew: true})
    },
    dayChange: function () {
      this.getTodayLessons()
    },
    checkInProgress: function (from,duration) {
      var fDate, endDate, cDate
      cDate = new Date()
      fDate = Date.parse(from)
      endDate = moment.parseZone(fDate).add(duration, 'm').toDate()

      if((cDate <= endDate && cDate >= fDate)) {
        return true
      }
      return false
    },
    checkDone: function (from,duration) {
      var fDate, endDate, cDate
      fDate = Date.parse(from)
      endDate = moment.parseZone(fDate).add(duration, 'm').toDate()
      cDate = new Date()

      if(endDate < cDate) {
        return true
      }
      return false
    },
    getHours: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('H:mm')
    },
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    addMarker(lat, lng, id) {
      const marker = {
        lat: lat,
        lng: lng,
        id: id,
      };
      this.markers.push({ position: marker });
      // this.center = marker;
    },
    getPrettyDate(d) {
      return moment.parseZone(d).format('DD MMM YYYY')
    },
    getTime(d) {
      return moment.parseZone(d).format('HH:mm')
    },
    getTodayLessons() {
      this.styleDate()
      let day = moment.parseZone(this.date).format('YYYY-MM-DD')
      this.loading = true
      httpClient
        .get('/event/api/v1/lessons/students/day/' + day)
        //.get('http://localhost:13007/api/v1/lessons/students/day/' + day)
        .then((resp) => {
          this.loading = false
          if(resp.data !== null) {
            this.lessons = resp.data

            resp.data.forEach(e => {
              if(e.Latitude != null && e.Longitude != null) {
                if(e.Latitude == "0.000000" || e.Longitude == "0.000000") {
                  bus.$emit('snackBarMsg', {info: true, msg: 'To see this feature working correctly please set your places gps coords'})
                } else {
                  this.addMarker(+e.Latitude, +e.Longitude, e.ID)
                }
              } else {
                if(e.Place != null) {
                  bus.$emit('snackBarMsg', {info: true, msg: 'To see this feature working correctly please set your place ' + e.Place + ' gps coords'})
                }
              }

              e.TotalStudent = 0
              e.TotalStaff = 0
              if(e.Students != null) {
                e.TotalStudent = e.Students.length
              }
            })

            this.items = []
            this.items.push({id: Math.random(), hidedot: true, type: 'day', lesson: null, date: day})

            this.lessons.forEach(e => {
              this.items.push({id: e.ID, hidedot: false, type: 'lesson', lesson: e, date: null})
            })
          }
        })
        .catch((error) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lessons - ' + error})
        })
    }
  },

  components: {
    LessonDialog,
    WorkViewFullDialog,
  }
};
</script>