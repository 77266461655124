<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <highcharts :options="chartOptionsTotal" :updateArgs="updateArgs"></highcharts>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <highcharts :options="chartOptionsPayment" :updateArgs="updateArgs"></highcharts>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import {Chart} from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart
  },
  data () {
    return {
      yearList: this.$store.state.yearList,
      monthList: this.$store.state.monthList,
      yearSelected: new Date().getFullYear(),
      monthSelected: 0,
      chartOptionsTotal: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          //verticalAlign: 'middle',
          text: this.$t('message.staffs'),
        },
        subtitle: {
          text: ''
        },
        legend: {
          labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
        },
        series: [{
          innerSize: '50%',
          name: 'Lessons',
          data: [],
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
        }]
      },
      chartOptionsPayment: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          //verticalAlign: 'middle',
          text: this.$t('message.staffs_payments'),
        },
        legend: {
          labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
        },
        series: [{
          innerSize: '50%',
          name: 'Paid',
          data: [],
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
        }]
      },
      updateArgs: [true, true, { duration: 1000 }]
    }
  },
  created() {
    bus.$on("ChartsMonth", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
    })
    bus.$on("ChartsYear", (e) => {
      this.yearSelected = e.year
      this.monthSelected = e.month
    })
  },
  mounted () {
    this.get()
    // Two charts = command twice
    document.getElementsByClassName('highcharts-credits')[0].remove()
    document.getElementsByClassName('highcharts-credits')[0].remove()
  },
  watch: {
    monthSelected() { this.get() },
    yearSelected() { this.get() }
  },
  methods: {
    get() {
      httpClient
        .get('mix/api/v1/reports/staffs/' + this.yearSelected + '/' + this.monthSelected)
        //.get('http://localhost:13007/api/v1/reports/staffs/' + this.yearSelected + '/' + this.monthSelected,)
        .then((resp) => {
          this.chartOptionsTotal.series[0].data = []
          this.chartOptionsPayment.series[0].data = []
          if(resp.data !== null) {
            resp.data.forEach(e => {
              if (e.StaffName !== undefined) {
                this.chartOptionsTotal.series[0].data.push({name: e.StaffName, y: e.Total})
                this.chartOptionsPayment.series[0].data.push({name: e.StaffName, y: +e.TotalPayment})
              }
            })
          }
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting staffs information'})
        })
    }
  }
}
</script>