import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import Start from '@/components/Public/Start'
import AccountActivation from '@/components/User/AccountActivation'
import SmartBotGeneral from '@/components/SmartBot/General'
import User from '@/components/User/Dashboard'
import Daily from '@/components/Calendar/Daily'
import AdminDashboard from '@/components/Administration/AdminDashboard'
import Calendar from '@/components/Calendar/Calendar'
import LessonList from '@/components/Lesson/LessonList'
import LessonView from '@/components/Lesson/LessonView'
import LessonTypeList from '@/components/LessonType/LessonTypeList'
import StudentList from '@/components/Student/StudentList'
import StudentView from '@/components/Student/StudentView'
import StaffList from '@/components/Staff/StaffList'
import StaffView from '@/components/Staff/StaffView'
import PlaceList from '@/components/Place/PlaceList'
import PlaceView from '@/components/Place/PlaceView'
import PackageList from '@/components/PackageService/PackageList'
import ServiceList from '@/components/PackageService/ServiceList'
import ServiceTypeList from '@/components/PackageService/ServiceTypeList'
import TransportList from '@/components/Transport/TransportList'
import TransportView from '@/components/Transport/TransportView'
import SupplierList from '@/components/Supplier/SupplierList'
import ChartsDashboard from '@/components/Charts/ChartsDashboard'
import Settings from '@/components/Settings/SettingsList'
import RoomList from '@/components/Room/RoomList'
import RoomView from '@/components/Room/RoomView'
import WorkList from '@/components/Work/WorkList'
import WorkView from '@/components/Work/WorkView'
import ItemList from '@/components/Item/ItemList'
import ItemView from '@/components/Item/ItemView'
import EquipmentList from '@/components/Equipment/EquipmentList'
import EquipmentView from '@/components/Equipment/EquipmentView'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  hash: false,
  routes: [
    { path: '/', name: 'Start', component: Start, meta: { requiresAuth: false } },
    { path: '/activation/:code', name: 'AccountActivation', component: AccountActivation, meta: { requiresAuth: false } },
    { path: '/chatbot', name: 'SmartBot', component: SmartBotGeneral, meta: { requiresAuth: true } },
    { path: '/user', name: 'User', component: User, meta: { requiresAuth: true } },
    { path: '/daily', name: 'Daily', component: Daily, meta: { requiresAuth: true } },
    { path: '/calendar', name: 'Calendar', component: Calendar, meta: { requiresAuth: true } },
    { path: '/lesson', name: 'Lesson List', component: LessonList, meta: { requiresAuth: true } },
    { path: '/lesson/:id', name: 'Lesson View', component: LessonView, meta: { requiresAuth: true } },
    { path: '/lessontypes', name: 'Lesson Types List', component: LessonTypeList, meta: { requiresAuth: true } },
    { path: '/student', name: 'List Student', component: StudentList, meta: { requiresAuth: true } },
    { path: '/student/:id', name: 'View Student', component: StudentView, meta: { requiresAuth: true } },
    { path: '/staff', name: 'Staff List', component: StaffList, meta: { requiresAuth: true } },
    { path: '/staff/:id', name: 'View Staff', component: StaffView, meta: { requiresAuth: true } },
    { path: '/packages', name: 'Package List', component: PackageList, meta: { requiresAuth: true } },
    { path: '/services', name: 'Service List', component: ServiceList, meta: { requiresAuth: true } },
    { path: '/servicetypes', name: 'Service Type List', component: ServiceTypeList, meta: { requiresAuth: true } },
    { path: '/place', name: 'Place List', component: PlaceList, meta: { requiresAuth: true } },
    { path: '/place/:id', name: 'Place View', component: PlaceView, meta: { requiresAuth: true } },
    { path: '/transport', name: 'Transport List', component: TransportList, meta: { requiresAuth: true } },
    { path: '/transport/:id', name: 'Transport View', component: TransportView, meta: { requiresAuth: true } },
    { path: '/supplier', name: 'Supplier List', component: SupplierList, meta: { requiresAuth: true } },
    { path: '/dashboard', name: 'User Dashboard', component: ChartsDashboard, meta: { requiresAuth: true, isAdmin: true } },
    { path: '/settings', name: 'Settings', component: Settings, meta: { requiresAuth: true, isAdmin: false } },
    { path: '/admin', name: 'Admin Dashboard', component: AdminDashboard, meta: { requiresAuth: true, isMaster: true } },
    { path: '/item', name: 'Item List', component: ItemList, meta: { requiresAuth: true } },
    { path: '/item/:id', name: 'Item View', component: ItemView, meta: { requiresAuth: true } },
    { path: '/room', name: 'Rooms', component: RoomList, meta: { requiresAuth: true } },
    { path: '/room/:id', name: 'View Room', component: RoomView, meta: { requiresAuth: true } },
    { path: '/works', name: 'Work List', component: WorkList, meta: { requiresAuth: true } },
    { path: '/work/:id', name: 'Work View', component: WorkView, meta: { requiresAuth: true } },
    { path: '/equipment', name: 'Equipment List', component: EquipmentList, meta: { requiresAuth: true } },
    { path: '/equipment/:id', name: 'Equipment View', component: EquipmentView, meta: { requiresAuth: true } },
    { path: '*', name: 'NotFound', component: Start }
  ]
})

router.beforeEach((to, from, next) => {
  const user = store.state.email
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (!user) {
      next({ path: '/', params: { nextUrl: to.fullPath } })
    } else {
      try {
        const isAdminRoute = to.matched.some(record => record.meta.isAdmin);
        const isMasterRoute = to.matched.some(record => record.meta.isMaster);

        if (isAdminRoute && !store.state.profile) {
          next({ name: 'Daily' }); // Redirect non-admin users
        } else if (isMasterRoute && !store.state.profile) {
          next({ name: 'Daily' }); // Redirect non-master users
        } else {
          next(); // User is authenticated and has access
        }
      } catch (error) {
        localStorage.clear()
        next({ path: '/', params: { nextUrl: to.fullPath } })
      }
    }
  } else {
    next()
  }
})

export default router