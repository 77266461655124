<template>
  <div>
    <v-toolbar elevation="1" rounded short>
      <v-toolbar-title>{{ $t("message.welcome") }} {{ this.user.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <div>
      </div>

      <!--<v-chip @click="openChangePassDialog()"
        class="ma-0 mx-1" color="green lighten-5" text-color="black"
        style="margin-top: 40px; ">
        <v-avatar left
          class="green darken-4" style="color: white !important">
          <v-icon small>mdi-lock</v-icon>
        </v-avatar>
        Alterar Password
      </v-chip>-->
    </v-toolbar>

    <br v-if="this.user.name === ''" />
    <v-card v-if="this.user.name === ''">
      <v-responsive>
        <v-card-title>
          Complete your profile
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-col cols="12" sm="6" md="6" lg="6" class="nm-NoPaddingTopBot">
              <v-text-field :label="$t('message.name')"
                :rules="nameRules"
                v-model="newUserName"
                autocomplete="nope"/>
            </v-col>
          </v-form>
          Please start updating your information, this is imporant to get a perfect experience usign our application.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!valid" @click="updateUserName()">{{ $t("message.update") }}</v-btn>
        </v-card-actions>
      </v-responsive>
    </v-card>

    <br v-if="this.user.name !== '' && this.userApps && this.userApps.length > 0"/>
    <v-card v-if="this.user.name !== '' && this.userApps && this.userApps.length > 0" :loading="loadingUserApps" elevation="0" >
      <v-responsive>
        <v-card-title>
          Your SmartSurf business's
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6"  v-for="app in userApps" :key="app.id">

              <v-card :class="{ 'light-green-background': app.logged_in }">
                <v-responsive>
                  <v-card-title>{{ app.name }}</v-card-title>
                  <v-card-text>
                    <span>
                      <v-chip class="ma-1" color="cyan lighten-4" text-color="black" @click="openApp('demo smart surf')">
                        <v-avatar left class="cyan darken-4" style="color: white !important">
                          <v-icon small>mdi-account</v-icon>
                        </v-avatar>
                        <span>{{ app.user_profile }}</span>
                      </v-chip>
                    </span>
                    
                    <span v-if="app.status === 'pending'">
                      <v-chip class="ma-1" color="yellow lighten-4" text-color="black" @click="openApp('demo smart surf')">
                        <v-avatar left class="yellow darken-4" style="color: white !important">
                          <v-icon small>mdi-gavel</v-icon>
                        </v-avatar>
                        <span>{{ $t(`message.app_status.${app.status}`) }}</span>
                      </v-chip>
                    </span>
                    <span v-if="app.status !== 'pending'">
                      <v-chip class="ma-1" color="blue lighten-4" text-color="black" @click="openApp('demo smart surf')">
                        <v-avatar left class="blue darken-4" style="color: white !important">
                          <v-icon small>mdi-check</v-icon>
                        </v-avatar>
                        <span>{{ $t(`message.app_status.${app.status}`) }}</span>
                      </v-chip>
                    </span>


                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="switchApp(app.ID)" v-if="app.status !== 'pending'">Swith</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="inviteAppUser()" v-if="app.status !== 'pending'">{{ $t("message.invite_user") }}</v-btn>
                  </v-card-actions>
                </v-responsive>
              </v-card>

            </v-col>
          </v-row>

        </v-card-text>
      </v-responsive>
    </v-card>

    <br v-if="this.user.name !== '' && this.userApps && this.userApps.length < 3"/>
    <v-card v-if="this.user.name !== '' && this.userApps && this.userApps.length < 3" :loading="loadingCreatingApp">
      <v-responsive>
        <v-card-title>
          Start/Automate your business using SmartSurf
        </v-card-title>
        <v-card-text>
          <v-form v-model="isNewAppValid">
            <v-text-field
              v-model="newApp.Name"
              :label="this.$t('message.application_name')"
              :rules="appNameRules"
              name="new-app"
              prepend-icon="mdi-application"
              type="text"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!isNewAppValid" @click="createNewApp()">{{ $t("message.create") }}</v-btn>
        </v-card-actions>
      </v-responsive>
    </v-card>

    <v-dialog v-model="changePassDialog" scrollable max-width="80%">
      <v-card :loading="loadingChangePass">
        <v-card-title>Alterar password</v-card-title>
        <v-card-text>
          <v-alert type="warning">
            Por favor memorize a sua password
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="changePass()">Change</v-btn>
          <v-btn color="darken-1" text @click="changePassDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddAppUser />
  </div>
</template>

<style scoped>
.light-green-background {
  background-color: #f4fbf6; /* Light green color */
}
</style>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'
import AddAppUser from './AddAppUser'

export default {
  data() {
    return {
      loading: false,
      loadingUpdateUser: false,
      loadingUserApps: false,
      loadingChangePass: false,
      loadingCreatingApp: false,
      changePassDialog: false,
      user: {},
      userApps: [],
      newApp:  {},
      newUserName: '',
      messages: 0,
      show: false,
      isValid: false,
      nameRules: [
        v => !!v || 'Name is required'
      ],
      isNewAppValid: false,
      appNameRules: [
        v => !!v || 'Application name is required'
      ],
    };
  },
  created () {
    this.user.name = this.$store.state.userName
    this.user.email = this.$store.state.email

    if (this.user.name !== '') {
      this.getUserApps()
    }
  },
  methods: {
    updateUserName() {
      this.loadingUpdateUser = true
      this.user.name = this.newUserName
      httpClient
        .put('/oauth/api/v1/smartsoft/user', this.user)
        //.put('http://localhost:13000/api/v1/smartsoft/user', this.user)
        .then(() => {
          this.$store.commit('setUserName', this.newUserName)
          this.$forceUpdate()
          this.loadingUpdateUser = false
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating name'})
        })
    },
    getUserApps() {
      this.loadingUserApps = true
      httpClient
        .get('/oauth/api/v1/smartsoft/user/apps')
        //.get('http://localhost:13000/api/v1/smartsoft/user/apps')
        .then((resp) => {
          if(resp.data.error) {
            bus.$emit('snackBarMsg', {error: true, msg: 'Error getting apps'})
          } else {
            this.userApps = resp.data.data
          }
          this.loadingUserApps = false
        })
        .catch(() => {
          this.loadingUserApps = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting apps'})
        })
    },
    switchApp(appID) {
      this.loadingUserApps = true
      httpClient
        .post('/oauth/api/v1/smartsoft/app/switch/' + appID)
        //.post('http://localhost:13000/api/v1/smartsoft/app/switch/' + appID)
        .then((resp) => {
          if(resp.data) {
            bus.$emit('snackBarMsg', {error: true, msg: 'Error switching apps'})
          } else {
            this.getUserApps()
          }
          this.loadingUserApps = false
        })
        .catch(() => {
          this.loadingUserApps = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting apps'})
        })
    },
    createNewApp() {
      this.loadingCreatingApp = true
      httpClient
        .post('/oauth/api/v1/smartsoft/app', this.newApp)
        //.post('http://localhost:13000/api/v1/smartsoft/app', this.newApp)
        .then((resp) => {
          if(!resp.data) {
            bus.$emit('snackBarMsg', {error: true, msg: 'Error creating application'})
          } else {
            // TODO: Handle errors
            if (resp.data.error) {
              bus.$emit('snackBarMsg', {error: true, msg: resp.data.message})
            }
            this.getUserApps()
          }
          this.loadingCreatingApp = false
        })
        .catch(() => {
          this.loadingCreatingApp = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating application'})
        })
    },
    inviteAppUser() {
      bus.$emit('openAddAppUser')
    },
    openApp(app) {
      console.log("Opening applicaton - ", app)
    },
    getPrettyDate(d) {
      return moment.parseZone(d).format('DD MMM YYYY')
    },
    getTime(d) {
      return moment.parseZone(d).format('HH:mm')
    },
    openChangePassDialog() {
      this.changePassDialog = true
    },
  },
  components: {
    AddAppUser,
  }
}
</script>