<template>
  <v-card :loading="loading">
    <v-card-title>
      Apps
      <v-spacer></v-spacer>
      <v-text-field v-model="search"
        append-icon="mdi-search" :label="$t('message.search')" single-line hide-details style="width: 30%" clearable></v-text-field> 
    </v-card-title>
    
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12" sm="4" md="3" lg="3"
          v-for="app in filteredRows" :key="app.Port" style="padding: 0px">
          <v-chip class="ma-1" color="info" small>{{ app.App }}</v-chip>
          <v-chip class="ma-1" color="default" small>{{ app.Port }}</v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      loading: false,
      search: '',
      rows: [],
      filteredRows: [],
    }
  },
  watch: {
    search: function (val) {
      this.filteredRows = this.rows.filter(function (a) {
        return a.Name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
    },
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/nmbot/ports')
        //.get('http://localhost:13007/api/v1/nmbot/ports')
        .then((r) => {
          this.loading = false
          this.rows = r.data
          this.filteredRows = this.rows
          this.filteredRows = this.filteredRows.sort((a, b) => (a > b ? 1 : -1))
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting apps and ports'})
        })
    },
  }
}
</script>
