<template>
  <div>
    <v-chip
      class="ma-1"
      color="blue lighten-5"
      text-color="black"
      @click="dialog = true"
    >
      <v-avatar
        left
        class="blue darken-4"
        style="color: white !important"
      >
        <v-icon small>mdi-plus</v-icon>
      </v-avatar>
      {{ buttonLable }}
    </v-chip>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card :loading="loading">
        <v-card-title><span class="headline">Select package</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8">
                <v-select
                  v-model="studentPackage.IDPackage"
                  :items="packageList"
                  item-text="Name"
                  item-value="ID"
                  item-disabled="Consumed"
                  label="Package"
                  required
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Quantity" class="purple-input" type="number" v-model.number="studentPackage.Quantity" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createStudentPackage()">Add</v-btn>
          <v-btn color="darken-1" text @click="dialog = false">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    studentID: Number,
    buttonLable: String,
  },
  data: () => ({
    dialog: false,
    packageList: [],
    studentPackage: {},
    loading: false,
  }),
  watch: {
    dialog: function (val) {
      if(val) {
        this.studentPackage = {}
        this.getPackages()
        this.studentPackage.IDStudent = this.studentID
      }
    }
  },
  methods: {
    getPackages: function () {
      this.loading = true
      httpClient
        .get('/package/api/v1/packages')
        //.get('http://localhost:13004/api/v1/packages')
        .then((resp) => {
          this.loading = false
          if(resp) {    
            this.packageList = resp.data
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Error getting packages'})
        })
    },
    createStudentPackage: function () {
      if(this.studentPackage.Quantity === undefined || this.studentPackage.Quantity === 0) {
        bus.$emit('snackBarMsg', {error: true, msg: 'Please insert the quantity'})
        return
      }
      this.loading = true
      httpClient
        .post('/package/api/v1/packages/student', this.studentPackage)
        //.post('http://localhost:13004/api/v1/packages/student', this.studentPackage)
        .then((r) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Package added to student'})
          bus.$emit('refreshStudentPackages')
          bus.$emit('refreshLessonStudentDialogPackages', {package: r.data})
          bus.$emit('refreshFormPackageServicePackages', {package: r.data})
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Error creating student package'})
        })
    },
  },
}
</script>