import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from "vue2-google-maps"
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import VueFlags from '@growthbunker/vueflags'
import i18n from '@/plugins/i18n'

Vue.use(HighchartsVue, {
	highcharts: Highcharts
})

Vue.config.productionTip = false

Vue.prototype.MSUrl = 'https://ms.smartsurf.pt/'

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC4_1TJqe4PU3BnhoNkE8AGTSxGZxHmZ5A",
    libraries: "places"
  }
})

Vue.use(VueFlags, {
  iconPath: '/flags/',
})

export const bus = new Vue()

new Vue({
  vuetify,
  router,
  store,
  created() {
    this.$store.dispatch('fetchYearList')
  },
  i18n,
  render: h => h(App)
}).$mount('#app')
