<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-calendar-range</v-icon> Lesson Types <v-chip
          class="mx-1"
          color="blue lighten-5"
          text-color="black"
          @click="createDialog()"
        >
          <v-avatar left
            class="blue darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-avatar>
          <span>{{ $t("message.create") }}</span>
        </v-chip>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        :label="$t('message.search')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.options="{ item }">
        <v-chip
          class="mx-1"
          color="blue lighten-5"
          text-color="black"
          @click="updateDialog(item)"
        >
          <v-avatar
            left
            class="blue darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-forward</v-icon>
          </v-avatar>
          {{ $t("message.update") }}
        </v-chip>
        <!--<v-chip class="mx-1" color="red lighten-5" text-color="black" @click="confirmDelete(item)">
          <v-avatar left
            class="red darken-4" style="color: white !important">
            <v-icon small>mdi-delete</v-icon>
          </v-avatar>
          Delete
        </v-chip>-->
      </template>
    </v-data-table>
    <!--<LessonTypeDeleteConfirm />-->
    <LessonTypeDialog />
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import LessonTypeDialog from './LessonTypeDialog'

export default {
  data () {
    return {
      loading: false,
      search: "",
      rows: [],
      columns: [
        { text: this.$t('message.name'), align: 'left', sortable: true, value: 'Name' },
        { text: 'Description', align: 'left', sortable: true, value: 'Description' },
        { text: this.$t('message.options'), sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25, 50]
    }
  },
  created () {
    this.getLessonTypes()
    bus.$on('refreshLessonTypes', () => { this.getLessonTypes() })
  },
  beforeDestroy () {
    bus.$off('refreshLessonTypes', null)
  },
  watch: { 'search' () {
      this.$store.commit('filtersLessonTypesList', this.search)
    }
  },
  methods: {
    getLessonTypes: function () {
      this.loading = true
      httpClient
        .get('/event/api/v1/lessontypes')
        //.get('http://localhost:13007/api/v1/lessontypes')
        .then((r) => {
          this.loading = false
          this.rows = r.data
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson types'})
        })
    },
    createDialog() {
      bus.$emit('openLessonTypeDialog', {lessonType: {}})
    },
    updateDialog(lT) {
      bus.$emit('openLessonTypeDialog', {lessonType: lT})
    }
  },
  components: {
    LessonTypeDialog,
  }
}
</script>