<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" offset-x>
      <template v-slot:activator="{ on }">
        <v-chip class="ma-0" color="cyan lighten-5" text-color="black" v-on="on">
          <v-avatar left class="cyan darken-4" style="color: white !important">
            <v-icon small>mdi-filter-variant</v-icon>
          </v-avatar>
          {{ $t("message.advanced_filter") }}
        </v-chip>
      </template>

      <v-card :loading="loading">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img src="@/assets/logo.png" alt="SmartSurf" width="50px">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Advanced</v-list-item-title>
              <v-list-item-subtitle>Filter</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="packages" color="purple" @change="filterPackages($event !== null, $event)"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Students with Packages</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="services" color="purple" @change="filterServices($event !== null, $event)"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Students with Services</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-dialog v-model="exportDialog" max-width="800">
      <template v-slot:activator="{ on }">
        <v-chip
          class="ma-1"
          color="light-green lighten-5"
          text-color="black"
          v-on="on"
          v-if="isAdmin"
        >
          <v-avatar
            left
            class="light-green darken-4"
            style="color: white !important"
          >
            <v-icon small>mdi-cloud-download-outline</v-icon>
          </v-avatar>
          Export
        </v-chip>
      </template>
      <v-card>
        <v-card-title class="headline">Export customer emails <small>(You can filter data with advanced filter)</small></v-card-title>
        <v-card-text>
          <v-textarea
            outlined
            name="export-textarea"
            label="Customer list"
            v-model="exportedCustomersEmail"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="exportDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { bus } from '../../main'

export default {
  name: 'AdvancedFilter',
  data: () => ({
    loading: false,
    isAdmin: false,
    fav: true,
    menu: false,
    packages: false,
    services: false,
    exportDialog: false,
    customer: {},
    valid: false,
    exportedCustomersEmail: '',
    exportedCustomerNumber: 0,
  }),
  watch: {
    exportDialog (val) {
      if (val) {
        this.getCustomersEmail()
      }
    }
  },
  created () {
    this.isAdmin = this.$store.state.profile === "Administrator"
    /* emited from TableStudent */
    bus.$on('exportedStudents', (emailListInfo) => {
      this.exportedCustomerNumber = 15
      this.exportedCustomersEmail = emailListInfo
    })
  },
  beforeDestroy () {
    bus.$off('exportedStudents', null)
  },
  methods: {
    filterPackages: (value, event) => {
      bus.$emit('filterPackages', event)
    },
    filterServices: (value, event) => {
      bus.$emit('filterServices', event)
    },
    getCustomersEmail: function () {
      bus.$emit('customersEmailRequested', true)
    }
  }
}
</script>