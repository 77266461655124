<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card :loading="loading" elevation="1">
          <v-card-title>
            <div class=".display-3"><v-icon left>mdi-map-marker</v-icon> <span contenteditable="true">{{ this.place.Name }}</span></div>
            <v-spacer></v-spacer>
            <PlaceDialog :placeAction="'update'" :placeDetails="place" />
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <gmap-map
                  :options="{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                  }"
                  :center="center"
                  :zoom="zoom"
                  :map-type-id="mayType"
                  style="width:100%; height: 150px;"
                >
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    @click="openPanel(m)"
                  ></gmap-marker>
                </gmap-map>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <span class="font-weight-medium">Latitude:</span> {{ place.Latitude }}
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <span class="font-weight-medium">Longitude:</span> {{ place.Longitude }}
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <span class="font-weight-medium">{{ $t("message.country") }}:</span> {{ place.Country }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import PlaceDialog from './PlaceDialog'

export default {
  data () {
    return {
      place: {},
      loading: false,
      center: { lat: 0, lng: 0 },
      zoom: 11,
      mayType: 'terrain',
      markers: [],
    }
  },
  created () {
    this.get()
  },
  methods: {
    get () {
      this.loading = true
      httpClient
        .get('/place/api/v1/places/' + this.$route.params.id)
        //.get('http://localhost:13007/api/v1/places/' + id)
        .then((response) => {
          this.loading = false
          this.place = response.data
          this.center = { lat: +this.place.Latitude, lng: +this.place.Longitude }
          this.addMarker(+this.place.Latitude, +this.place.Longitude, this.place.Id)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting place'})
        })
    },
    addMarker(lat, lng, id) {
      const marker = {
        lat: lat,
        lng: lng,
        id: id,
      };
      this.markers.push({ position: marker });
    }
  },
  components: {
    PlaceDialog
  }
}
</script>