var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loadingLesson}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-range")]),_vm._v(" "+_vm._s(_vm.$t("message.events"))+" "),_c('StudentLessonDialog',{attrs:{"studentID":_vm.customerID}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":_vm.$t('message.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.rows,"search":_vm.search,"items-per-page":10,"server-items-length":_vm.totalRows,"options":_vm.options,"sort-by":['DateTime'],"sort-desc":[true],"footer-props":{
      itemsPerPageOptions: _vm.rowsPerPageItems,
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right'
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.DateTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateTime))+" ")]}},{key:"item.Credits",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openFormPackageServicePopup(item.ID)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-plus")])],1),(item.PackageName != null)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"info","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-package-variant")]),_vm._v(" "+_vm._s(item.PackageName)+" ")],1):_vm._e(),(item.ServiceName)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":"info","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-room-service-outline")]),_vm._v(" "+_vm._s(item.ServiceName)+" ")],1):_vm._e()]}},{key:"item.options",fn:function({ item }){return [_c('router-link',{attrs:{"to":'/lesson/' + item.ID}},[_c('v-chip',{attrs:{"color":"blue lighten-5","text-color":"black"}},[_c('v-avatar',{staticClass:"blue darken-4",staticStyle:{"color":"white !important"},attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-forward")])],1),_vm._v(" View ")],1)],1),_c('v-chip',{staticClass:"mx-1",attrs:{"color":"red lighten-5","text-color":"black"},on:{"click":function($event){return _vm.confirmDelete(item)}}},[_c('v-avatar',{staticClass:"red darken-4",staticStyle:{"color":"white !important"},attrs:{"left":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-remove")])],1),_vm._v(" Remove ")],1)]}}])}),_c('FormPackageService'),_c('StudentLessonDeleteConfirm')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }