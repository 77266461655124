<template>
  <div>
    <v-chip
      class="ma-1"
      color="blue lighten-5"
      text-color="black"
      @click="openNewDialog()"
    >
      <v-avatar
        left
        class="blue darken-4"
        style="color: white !important"
      >
        <v-icon small>mdi-plus</v-icon>
      </v-avatar>
      {{ $t("message.create") }}
    </v-chip>
    <v-dialog v-model="dialog" scrollable max-width="500">
      <v-card :loading="loading">
        <v-card-title><span v-if="isNew">{{ $t("message.create_supplier") }}</span><span v-if="!isNew">{{ $t("message.update_supplier") }}</span></v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-text-field
              v-model="supplier.Name"
              :rules="nameRules"
              :label="$t('message.name')"
              required
            ></v-text-field>
            <v-text-field
              v-model="supplier.Email"
              label="Email"
            ></v-text-field>
            <v-text-field
              v-model="supplier.Phone"
              label="Phone"
            ></v-text-field>
            <v-text-field
              v-model="supplier.RateValue"
              label="Rate value"
              required
            ></v-text-field>
            <v-text-field
              v-model="supplier.VatNumber"
              label="Vat number"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-if="isNew" :disabled="!valid" @click="create()">{{ $t("message.create") }}</v-btn>
          <v-btn color="primary" text v-if="!isNew" :disabled="!valid" @click="update()">{{ $t("message.update") }}</v-btn>
          <v-btn text @click="dialog = false">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data: () => ({
    dialog: false,
    loading: false,
    isNew: false,
    exportDialog: false,
    supplier: {},
    valid: false,
    nameRules: [
      v => !!v || 'Name is required'
    ],
    /*emailRules: [
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phoneRules: [
      v => /\+?[0-9]+/.test(v) || 'Phone must be valid'
    ],
    rateValueRules: [
      v => /[0-9]+/.test(v) || 'Seat number must be valid'
    ],
    vatNumberRules: [
      v => /[0-9]+/.test(v) || 'Seat number must be valid'
    ]*/
  }),
  created () {
    bus.$on('supplierDialog', (data) => {
      this.supplier = data.supplier
      this.isNew = data.isNew
      this.dialog = true
    })
  },
  beforeDestroy () {
    bus.$off('supplierDialog', null)
  },
  methods: {
    openNewDialog: function () {
      this.supplier = {}
      this.isNew = true
      this.dialog = true
    },
    create: function () {
      this.loading = true
      httpClient
        .post('/mix/api/v1/suppliers', this.supplier)
        .then(() => {
          this.loading = false
          this.dialog = false
          this.supplier = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Supplier successfully created'})
          bus.$emit('refreshSupplierList', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating supplier'})
        })
    },
    update: function () {
      this.loading = true
      httpClient
        .put('/mix/api/v1/supplier/' + this.supplier.ID, this.supplier)
        .then(() => {
          this.loading = false
          this.dialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Supplier updated'})
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating supplier'})
        })
    },
  }
}
</script>