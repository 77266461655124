<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>{{ deassociateService.Name }}</v-card-title>
      <v-card-text>
        <p>{{ deassociateService.Description }}</p>
        <v-alert v-if="packageIsInUse()"
          density="compact"
          type="warning"
          title="Alert title"
        > 
          This package is already in used <b>{{ currentPackage.TotalUsage }} times</b>.
        </v-alert>
        <v-alert v-if="packageIsInUse()"
          density="compact"
          type="info"
          title="Alert title"
        >
          Please take into consideration the option of creating a new package instead of modifying the existing one.
          This approach will help safeguard your data and prevent any potential compromises.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="deleteSrv()" color="red" text>Confirm Delete</v-btn>
        <v-btn @click="dialog = false" color="primary" text>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      deassociateService: {},
      currentPackage: {},
    }
  },
  created () {
    bus.$on('openPackageServiceDeleteConfirm', (data) => {
      this.dialog = true
      this.deassociateService = data.srv
      this.currentPackage = data.pck
    })
  },
  methods: {
    packageIsInUse() {
      return this.currentPackage.TotalUsage !== undefined && this.currentPackage.TotalUsage !== 0
    },
    deleteSrv: function () {
      this.loading = true
      httpClient
        .delete('package/api/v1/packages/services/' + this.deassociateService.IDPackage + '/' + this.deassociateService.IDService)
        //.delete('http://localhost:13007/api/v1/packages/services/' + this.deassociateService.IDPackage + '/' + this.deassociateService.IDService)
        .then(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Service removed from package'})
          bus.$emit('refreshPackageServices')
          this.dialog = false
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error removing service from package'})
        })
    }
  }
}
</script>
