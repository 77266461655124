<template>
  <span>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Select equipment for {{ studentName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="studentItem.IDBoard"
                  :items="boardList"
                  item-text="Name"
                  item-value="ID"
                  item-disabled="Unavailable"
                  :label="$t('message.board')"
                  required
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="studentItem.IDWetsuit"
                  :items="wetsuitList"
                  item-text="Name"
                  item-value="ID"
                  item-disabled="Unavailable"
                  :label="$t('message.wetsuit')"
                  required
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addOrRemove()">{{ $t("message.add") }}</v-btn>
          <v-btn color="darken-1" text @click="dialog = false">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data: () => ({
    dialog: false,
    loading: false,
    boardList: [],
    wetsuitList: [],
    studentItem: {},
    studentName: "",
  }),
  created() {
    bus.$on('openLessonStudentItemsDialog', (data) => {
      this.studentName = data.studentName
      this.studentItem.IDLesson = data.idLesson
      this.studentItem.IDStudent = data.idStudent
      this.studentItem.IDBoard = data.idBoard
      this.studentItem.IDWetsuit = data.idWetsuit
      this.getItems()
      this.dialog = true
    })
  },
  beforeDestroy () {
    bus.$off('openLessonStudentItemsDialog', null)
  },
  methods: {
    getItems: function () {
      this.loading = true
      httpClient
        .get('/mix/api/v1/items')
        //.get('http://localhost:13007/api/v1/items')
        .then((r) => {
          this.loading = false
          if(r) {
            this.boardList = r.data.filter(item => item.Type === 'Board' )
            this.wetsuitList = r.data.filter(item => item.Type === 'Wetsuit' )
          }
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Error getting packages'})
        })
    },
    addOrRemove: function () {
      this.loading = true
      if(this.studentItem.IDBoard === undefined && this.studentItem.IDWetsuit === undefined) {
        httpClient
          .delete('/event/api/v1/lessonitem/' + this.studentItem.IDLesson + '/' + this.studentItem.IDStudent)
          //.delete('http://localhost:13007/api/v1/lessonitem/' + this.studentItem.IDLesson + '/' + this.studentItem.IDStudent)
          .then(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: false, msg: 'Equipment removed'})
            bus.$emit('refreshLessonStudentList')
            bus.$emit('refreshLessonItems')
            this.dialog = false
          })
          .catch(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: true, msg: 'Error creating student items'})
          })
      } else {
        httpClient
          .post('/event/api/v1/lessonitem', this.studentItem)
          //.post('http://localhost:13007/api/v1/lessonitem', this.studentItem)
          .then(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: false, msg: 'Equipment added to student'})
            bus.$emit('refreshLessonStudentList')
            bus.$emit('refreshLessonItems')
            this.dialog = false
          })
          .catch(e => {
            this.loading = false
            const responseData = JSON.parse(e.request.response)
            bus.$emit('snackBarMsg', {error: true, msg: responseData.error})
          })
      }
    },
  },
}
</script>