<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-calendar-range</v-icon> {{ $t("message.events") }} 
      <template>
        <v-chip class="ma-1" color="blue lighten-5" text-color="black" @click="createLesson()">
          <v-avatar left class="blue darken-4" style="color: white !important">
            <v-icon small>mdi-plus</v-icon>
          </v-avatar>
          <span>{{ $t("message.create") }}</span>
        </v-chip>
      </template>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-search" :label="$t('message.search')"
        single-line hide-details clearable></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page="10"
      :server-items-length="totalRows"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.DateTime="{item}">
        {{ formatDate(item.DateTime) }}
      </template>
      <template v-slot:item.Duration="{ item }">
        <v-edit-dialog @open="openPlaceEdit"> {{ item.Duration }}
          <template v-slot:input>
            <v-select
              v-model="item.Duration"
              :items="[30,60,90,120,180]"
              menu-props="auto"
              label="Select duration"
              hide-details
              prepend-icon="mdi-map"
              single-line
            ></v-select>
            <v-btn color="primary" text @click="updateLesson(item)">Save</v-btn>        
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.Place="{ item }">
        <v-edit-dialog @open="openPlaceEdit"> {{ item.Place }}
          <template v-slot:input>
            <v-select
              v-model="item.IDPlace"
              :items="places"
              item-text="Name"
              item-value="ID"
              menu-props="auto"
              label="Select place name"
              hide-details
              prepend-icon="mdi-map"
              single-line
            ></v-select>
            <v-btn color="primary" text @click="updateLesson(item)">Save</v-btn>          
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.Type="{ item }">
        <v-edit-dialog @open="openLessonTypeEdit"> {{ item.Type }}
          <template v-slot:input>
            <v-select
              v-model="item.IDType"
              :items="types"
              item-text="Name"
              item-value="ID"
              menu-props="auto"
              label="Select type name"
              hide-details
              prepend-icon="mdi-map"
              single-line
            ></v-select><v-btn color="primary" text @click="updateLesson(item)">Save</v-btn>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.options="{ item }">
        <router-link v-bind:to="'/lesson/' + item.ID">
          <v-chip class="m-0 s-chip" color="blue lighten-5">
            <v-avatar class="blue darken-4" style="color: white !important">
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
          </v-chip>
        </router-link>
        <v-chip v-if="userRole === 'Administrator'" class="mx-1 s-chip" color="red lighten-5" @click="confirmDelete(item)">
          <v-avatar class="red darken-4" style="color: white !important">
            <v-icon small>mdi-delete</v-icon>
          </v-avatar>
        </v-chip>
      </template>
    </v-data-table>
    <LessonDialog />
    <LessonDeleteConfirm />
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import moment from 'moment'
import LessonDialog from './LessonDialog'
import LessonDeleteConfirm from './LessonDeleteConfirm'

export default {
  data () {
    return {
      search: this.$store.state.filters.lesson.list,
      rows: [],
      columns: [
        { text: this.$t('message.start_time'), align: 'left', sortable: true, value: 'DateTime' },
        { text: this.$t('message.duration'), align: 'left', sortable: true, value: 'Minutes' },
        { text: this.$t('message.place'), align: 'left', sortable: true, value: 'Place' },
        { text: this.$t('message.type'), align: 'left', sortable: true, value: 'Type' },
        { text: this.$t('message.options'), sortable: false, value: 'options' }
      ],
      rowsPerPageItems: [5, 10, 25, 50],
      places: [],
      types: [],
      loading: false,
      totalRows: 0,
      options: {},
      userRole: '',
      sortBy: 'DateTime',
      sortDesc: true,
    }
  },
  created () {
    this.userRole = this.$store.state.profile
    bus.$on('refreshLessons', () => { this.getLessons() })
  },
  beforeDestroy () {
    bus.$off('refreshLessons', null)
  },
  watch: {
    'search' () {
      this.$store.commit('filtersLessonList', this.search)
    },
    options: {
      handler () {
        this.getLessons()
      },
      deep: true,
    },
  },
  methods: {
    formatDate: function (dateToFormat) {
      return moment.parseZone(dateToFormat).format('DD-MM-YYYY HH:mm')
    },
    createLesson() {
      bus.$emit("openLessonCreateEventDialog")
    },
    getLessons: function () {
      this.loading = true
      httpClient
        .get('/event/api/v1/lessons?'+
        //.get('http://localhost:13007/api/v1/lessons?'+
          'pageNumber='+this.options.page+'&pageSize='+this.options.itemsPerPage+
          '&sortBy='+this.options.sortBy+'&sortDesc='+this.options.sortDesc)
        .then((r) => {
          this.loading = false
          this.rows = r.data.events
          this.totalRows = r.data.totalRows
        })
        .catch((e) => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lessons'})
          if (e.response.status === 403) {
            bus.$emit('snackBarClose')
          }
        })
    },
    updateLesson: function(lesson) {
      this.loading = true
      httpClient
        .put('/event/api/v1/lessons/' + lesson.ID, lesson)
        .then((r) => {
          this.loading = false
          this.lesson = r.data
          this.getLessons()
          bus.$emit('snackBarMsg', {error: false, msg: this.$t('message.event_updated')})
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating event'})
        })
    },
    /* Inline Edit methods */
    openPlaceEdit () {
      httpClient
        .get('/place/api/v1/places')
        .then((r) => {
          this.places = r.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting places'})
        })
    },
    openLessonTypeEdit () {
      httpClient
        .get('/event/api/v1/lessontypes')
        .then((r) => {
          this.types = r.data
        })
        .catch(() => {
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting lesson types'})
        })
    },
    confirmDelete: function (l) {
      bus.$emit('openLessonDeleteConfirm', {lesson: l})
    }
  },
  components: {
    LessonDialog,
    LessonDeleteConfirm
  }
}
</script>

<style>
a { text-decoration: none }
.v-small-dialog__content { height: 100px }
</style>