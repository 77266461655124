<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <template v-slot:activator="{ on }">
      <v-chip
        class="ma-1"
        color="blue lighten-5"
        text-color="black"
        v-on="on"
      >
        <v-avatar
          left
          class="blue darken-4"
          style="color: white !important"
        >
          <v-icon small>{{ btnIcon }}</v-icon>
        </v-avatar>
        <span>{{ btnText }}</span>
      </v-chip>
    </template>
    <v-card :loading="loading">
      <v-card-title><span v-if="isNew">Create room</span><span v-if="!isNew">Update room</span></v-card-title>
      <v-card-text>
        <br />
        <v-form v-model="valid">
          <v-text-field
            v-model="room.Name"
            :rules="nameRules"
            label="Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="room.BedNumber"
            label="Bed Number"
            type="number"
            required
          ></v-text-field>
          <v-autocomplete
            prepend-icon="mdi-toggle-switch-off-outline"
            v-model="room.State"
            :items="states"
            item-text="Name"
            item-value="ID"
            label="State"
            placeholder="Select state.."
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="!valid" v-if="isNew" @click="create()">{{ $t("message.create") }}</v-btn>
        <v-btn color="primary" text :disabled="!valid" v-if="!isNew" @click="update()">{{ $t("message.update") }}</v-btn>
        <v-btn text @click="dialog = false">{{ $t("message.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  props: {
    roomAction: String,
    roomDetails: {}
  },
  data: () => ({
    room: {},
    states: [{ Name: "Active", ID: "Active" }, { Name: "Inactive", ID: "Inactive" }],
    isNew: true,
    dialog: false,
    loading: false,
    valid: false,
    nameRules: [
      v => !!v || 'Name is required'
    ]
  }),
  computed: {
    btnIcon: function () {
      if (this.roomAction === 'update') {
        return 'mdi-pencil'
      } else {
        return 'mdi-plus'
      }
    },
    btnText: function () {
      if (this.roomAction === 'update') {
        return 'Edit'
      } else {
        return 'Create'
      }
    }
  },
  watch: {
    dialog: function (val) {
      if(val) {
        if (this.roomAction === 'update') {
          this.isNew = false
          this.room = this.roomDetails
        }
      }
    },
  },
  methods: {
    create: function () {
      this.loading = true
      // Explicity number convertion (IT IS NEEDED)
      this.room.BedNumber = parseInt(this.room.BedNumber)

      httpClient
        .post('/mix/api/v1/rooms', this.room)
        //.post('http://localhost:13007/api/v1/rooms', this.room)
        .then(() => {
          this.loading = false
          this.dialog = false
          this.room = {}
          bus.$emit('snackBarMsg', {error: false, msg: 'Room successfully created'})
          bus.$emit('refreshRoomList', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error creating room'})
        })
    },
    update: function () {
      this.loading = true

      // Explicity number convertion (IT IS NEEDED)
      this.room.BedNumber = parseInt(this.room.BedNumber)

      httpClient
        .put('/mix/api/v1/rooms/' + this.room.ID, this.room)
        //.put('http://localhost:13007/api/v1/rooms/' + this.room.ID, this.room)
        .then(() => {
          this.loading = false
          this.dialog = false
          bus.$emit('snackBarMsg', {error: false, msg: 'Room updated'})
          bus.$emit('refreshRoomList', null)
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error updating room'})
        })
    }
  }
}
</script>