<template>
  <v-card class="my-0" :loading="loading" elevation="0">
    <v-card-title>
      <v-icon left>mdi-account-group</v-icon> {{ $t("message.customers") }}
      <LessonStudentDialog :lessonID="this.lessonID" />

      <v-tooltip bottom v-if="!showTable">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="showTable = !showTable">mdi-view-list</v-icon>
        </template>
        <span>View cards</span>
      </v-tooltip>
      <v-tooltip bottom v-if="showTable">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click="showTable = !showTable">mdi-view-module</v-icon>
        </template>
        <span>View list</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        :label="$t('message.search')"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-card-text v-if="!showTable">
      <div class="layout row" style="flex-wrap: wrap !important;">
        <div class="flex" v-for="i in filteredRows" :key="i.ID">
      <!-- BUG WITH PRODUCTION BUILD - the style .layout is loaded after .row witch replaces the flex-wrap with the value "nowrap" -->
      <!--<v-layout row>
        <v-flex wrap v-for="i in filteredRows" :key="i.ID">-->
          <v-card class="mx-1 my-2" outlined>
            <v-card-title>
              {{ i.Name.substring(0, 15) }}
            </v-card-title>
            <v-card-text>
              <p></p>
              <p style="margin: 0px"><b>Packages & Services</b></p>
              <p class="display-0" style="margin: 0px">
                <v-btn icon @click="openFormPackageServicePopup(item.ID, item.Name)">
                  <v-icon color="info">mdi-plus</v-icon>
                </v-btn>
                <v-chip v-if="i.Package != null"
                  class="ma-0 mx-1"
                  color="info"
                  outlined
                >
                  <v-icon left>mdi-package-variant</v-icon>
                  {{ i.Package }}
                </v-chip>
                <v-chip v-if="i.Service != null"
                  class="ma-0 mx-1"
                  color="info"
                  outlined
                >
                  <v-icon left>mdi-room-service-outline</v-icon>
                  {{ i.Service }}
                </v-chip>
              </p>
              <p style="margin: 0px"><b>Equipment</b></p>
              <p class="display-0" style="margin: 0px">
                <v-btn icon @click="openItemsPopup(i.ID, i.Name, i.IDBoard, i.IDWetsuit)">
                  <v-icon color="primary">mdi-nas</v-icon>
                </v-btn>
                <v-chip v-if="i.Board !== undefined"
                  class="ma-0 mx-1"
                  color="primary"
                  outlined
                >
                  {{ i.Board }}
                </v-chip>
                <v-chip v-if="i.Wetsuit !== undefined"
                  class="ma-0 mx-1"
                  color="primary"
                  outlined
                >
                  {{ i.Wetsuit }}
                </v-chip>
              </p>
              <p style="margin: 0px"><b>Transport</b></p>
              <p class="display-0" style="margin: 0px">
                <v-btn icon @click="openTransportPopup(i.ID, i.Name, i.IDTransport)">
                  <v-icon color="green">mdi-train-car</v-icon>
                </v-btn>
                <v-chip v-if="i.Transport != null"
                  class="ma-0 mx-1"
                  color="green"
                  outlined
                >
                  {{ i.Transport }}
                </v-chip>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link v-bind:to="'/student/' + i.ID">
                <v-chip color="blue lighten-5">
                  <v-avatar left class="blue darken-4" style="color: white !important">
                    <v-icon small>mdi-forward</v-icon>
                  </v-avatar>
                </v-chip>
              </router-link>
              <v-chip v-if="userProfile === 'Administrator' || userProfile === 'Master'"
                @click="remove(i.ID)"
                class="s-chip" color="red lighten-5">
                <v-avatar left class="red darken-4" style="color: white !important">
                  <v-icon small>mdi-account-remove</v-icon>
                </v-avatar>
              </v-chip>
            </v-card-actions>
          </v-card>
        <!--</v-flex>
      </v-layout>-->
        </div>
      </div>
    </v-card-text>
    <v-data-table
      v-if="showTable"
      :headers="columns"
      :items="rows"
      :search="search"
      :items-per-page="10"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.Package="{ item }">
        <v-btn icon @click="openFormPackageServicePopup(item.ID, item.Name)">
          <v-icon color="info">mdi-plus</v-icon>
        </v-btn>
        <v-icon color="info" left v-if="item.Package">mdi-package-variant</v-icon> {{ item.Package }}
        <v-icon color="info" left v-if="item.Service">mdi-room-service-outline</v-icon> {{ item.Service }}
        <!--<v-chip v-if="item.Package != null"
          class="ma-0"
          color="info"
          outlined
          @click="openStudentPackageDetails(item)"
        >
          <v-icon left>mdi-package-variant</v-icon>
          {{ item.Package }}
        </v-chip>-->
        <!--<v-chip v-if="item.Service != null"
          class="ma-0 mx-1"
          color="info"
          outlined
          @click="openStudentServiceDetails(item)"
        >
          <v-icon left>mdi-room-service-outline</v-icon>
          {{ item.Service }}
        </v-chip>-->
      </template>
      <template v-slot:item.Equipment="{ item }">
        <v-btn icon @click="openItemsPopup(item.ID, item.Name, item.IDBoard, item.IDWetsuit)">
          <v-icon color="primary">mdi-nas</v-icon>
        </v-btn>
        <small>{{ item.Board }}</small><br><small>{{ item.Wetsuit }}</small>
        <!--<v-chip v-if="item.Board !== undefined"
          class="ma-0"
          color="primary"
          outlined
        >
        {{ item.Board }}
        </v-chip>
        <v-chip v-if="item.Wetsuit !== undefined"
          class="ma-0 mx-1"
          color="primary"
          outlined
        >
          {{ item.Wetsuit }}
        </v-chip>-->
      </template>
      <template v-slot:item.Transport="{ item }">
        <v-btn icon @click="openTransportPopup(item.ID, item.Name, item.IDTransport)">
          <v-icon color="green">mdi-train-car</v-icon>
        </v-btn>
        {{ item.Transport }}
        <!--<v-chip v-if="item.Transport != null"
          class="ma-0 mx-1"
          color="green"
          outlined
        >
          {{ item.Transport }}
        </v-chip>-->
      </template>
      <!--<template v-slot:item.Supplier="{ item }">
        <v-btn icon @click="openSupplierPopup(item.ID, item.Name, item.IDSupplier)">
          <v-icon color="green">mdi-factory</v-icon>
        </v-btn>
        {{ item.Supplier }}
        <v-chip v-if="item.Supplier != null"
          class="ma-0 mx-1"
          color="green"
          outlined
        >
          {{ item.Supplier }}
        </v-chip>
      </template>-->
      <template v-slot:item.options="{ item }">
        <router-link v-bind:to="'/student/' + item.ID">
          <v-chip class="s-chip" color="blue lighten-5" text-color="black">
            <v-avatar class="blue darken-4" style="color: white !important">
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
          </v-chip>
        </router-link>
        <v-chip v-if="userProfile === 'Administrator' || userProfile === 'Master'" @click="confirmDelete(item.ID, item.Name)"
          class="s-chip" color="red lighten-5" text-color="black">
          <v-avatar class="red darken-4" style="color: white !important">
            <v-icon small>mdi-account-remove</v-icon>
          </v-avatar>
        </v-chip>
      </template>
    </v-data-table>

    <FormPackageService />
    <LessonStudentTransportDialog />
    <LessonStudentItemsDialog />
    <StudentPackageDetailsView />
    <StudentServiceDetailsView />
    <LessonStudentDeleteConfirm />
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import LessonStudentDialog from './LessonStudentDialog.vue'
import FormPackageService from '../PackageService/FormPackageService'
import LessonStudentTransportDialog from './LessonStudentTransportDialog.vue'
import LessonStudentItemsDialog from './LessonStudentItemsDialog.vue'
import StudentPackageDetailsView from '../Student/StudentPackageDetailsView'
import StudentServiceDetailsView from '../Student/StudentServiceDetailsView'
import LessonStudentDeleteConfirm from './LessonStudentDeleteConfirm.vue'

export default {
  props: {
    lessonID: Number
  },
  data () {
    return {
      userProfile: "",
      loading: false,
      search: '',
      rows: [],
      filteredRows: [],
      rowsPerPageItems: [5, 10, 25, 50],
      columns: [
        { text: this.$t('message.name'), align: 'left', value: 'Name' },
        { text: 'Credits', sortable: false, value: 'Package' },
        { text: this.$t('message.equipment'), sortable: false, value: 'Equipment' },
        { text: this.$t('message.transport'), value: 'Transport' },
        //{ text: 'Supplier', sortable: false, value: 'Supplier' },
        { text: this.$t('message.options'), align: 'left', sortable: false, value: 'options' }
      ],
      showTable: true,
    }
  },
  watch: {
    search: function (val) {
      this.filteredRows = this.rows.filter(function (a) {
        return a.Name.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
    },
  },
  created () {
    this.userProfile = this.$store.state.profile
    this.getLessonStudents()
    bus.$on('refreshLessonStudentList', () => { this.getLessonStudents() })
  },
  beforeDestroy () {
    //bus.$off('refreshLessonStudentList', null)
  },
  methods: {
    getLessonStudents: function () {
      this.loading = true
      httpClient
        .get('/customer/api/v1/students/lesson/' + this.lessonID)
        //.get('http://localhost:13007/api/v1/students/lesson/' + this.lessonID)
        .then((r) => {
          this.loading = false
          this.rows = r.data
          this.filteredRows = this.rows
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting student lessons'})
        })
    },
    confirmDelete: function (idStudent, nameStudent) {
      bus.$emit('openLessonStudentDeleteConfirm', {idLesson: this.lessonID, idStudent: idStudent, nameStudent: nameStudent})
    },
    remove (idStudent) {
      this.loading = true
      httpClient
        .delete('/event/api/v1/lessonstudent/' + this.lessonID + '/' + idStudent)
        //.delete('http://localhost:13007/api/v1/lessonstudent/' + this.lessonID + '/' + idStudent)
        .then(() => {
          bus.$emit('snackBarMsg', {error: false, msg: 'Student removed successfully'})
          this.loading = false
          this.getLessonStudents()
          bus.$emit("refreshLessonItems")
          bus.$emit("refreshLessonTransports")
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error removing student'})
        })
    },
    openFormPackageServicePopup (sID, sName) {
      bus.$emit('openFormPackageServiceDialog', {idLesson: this.lessonID, idStudent: sID, studentName: sName})
    },
    openItemsPopup (sID, sName, idBoard, idWetsuit) {
      bus.$emit('openLessonStudentItemsDialog', {idLesson: this.lessonID, idStudent: sID, studentName: sName, idBoard: idBoard, idWetsuit: idWetsuit})
    },
    openTransportPopup (sID, sName, transportID) {
      bus.$emit('openLessonStudentTransportDialog', {idLesson: this.lessonID, idStudent: sID, studentName: sName, idTransport: transportID})
    },
    openSupplierPopup (sID, sName, supplierID) {
      bus.$emit('openLessonStudentSupplierDialog', {idLesson: this.lessonID, idStudent: sID, studentName: sName, idSupplier: supplierID})
    },
    openStudentPackageDetails: function (s) {
      let pck = {}
      pck.IDStudentPackage = s.IDPackage

      bus.$emit('openStudentPackageDetails', {pck: pck})
    },
    openStudentServiceDetails: function (s) {
      let srv = {}
      srv.IDStudentService = s.IDService

      bus.$emit('openStudentServiceDetails', {srv: srv})
    }
  },
  components: {
    LessonStudentDialog,
    FormPackageService,
    LessonStudentTransportDialog,
    LessonStudentItemsDialog,
    StudentPackageDetailsView,
    StudentServiceDetailsView,
    LessonStudentDeleteConfirm
  }
}
</script>
