import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)


const messages = {
  pt: {
    message: {
      "app_status": {
        "pending": "Pendente",
        "active": "Ativo",
        "inactive": "Inativo",
        "blocked": "Bloqueado"
      },
      add: 'Adicionar',
      name: 'Nome',
      close: 'Fechar',
      back: 'Voltar',
      back_to_list: 'Voltar para a lista',
      create: 'Criar',
      update: 'Atualizar',
      delete: 'Excluir',
      copy: 'Copiar',
      hello: 'Olá',
      today_events: 'Eventos de hoje',
      welcome: 'Bem-vindo',
      goodbye: 'Adeus',
      thank_you: 'Obrigado',
      yes: 'Sim',
      no: 'Não',
      please: 'Por favor',
      sorry: 'Desculpe',
      excuse_me: 'Com licença',
      good_morning: 'Bom dia',
      good_afternoon: 'Boa tarde',
      good_night: 'Boa noite',
      search: 'Procurar',
      comments: 'Comentários',
      update_comments: 'Atualizar comentários',
      comments_updated: 'Comentários atualizados',
      instructors: 'Instrutores',
      events: 'Eventos',
      event_types: 'Tipos de eventos',
      customers: 'Clientes',
      staff: 'Funcionário',
      create_staff: 'Criar Funcionário',
      update_staff: 'Atualizar Funcionário',
      staffs: 'Funcionários',
      day: 'Dia',
      week: 'Semana',
      month: 'Mês',
      year: 'Ano',
      fourdays: '4 Dias',
      be_prepared: 'Esteja preparado',
      event_at: 'Evento em',
      notes: 'Notas',
      boards: 'Pranchas',
      board: 'Prancha',
      wetsuits: 'Fatos',
      wetsuit: 'Fato',
      country: 'País',
      select_country: 'Selecione o país',
      packages: 'Pacotes',
      services: 'Serviços',
      place: 'Local',
      places: 'Locais',
      staffs_payments: 'Pagamentos de funcionários',
      supplier: 'Fornecedor',
      create_supplier: 'Criar Fornecedor',
      update_supplier: 'Atualizar Fornecedor',
      suppliers: 'Fornecedores',
      equipment: 'Equipamento',
      create_equipment: 'Criar Equipamento',
      update_equipment: 'Atualizar Equipamento',
      equipments: 'Equipamentos',
      transports: 'Transportes',
      transport: 'Transporte',
      rooms: 'Quartos',
      options: 'Opções',
      quantity: 'Quantidade',
      type: 'Tipo',
      manage_your_packages: 'Gerencie seus pacotes',
      manage_your_services: 'Gerencie seus serviços',
      manage_your_places: 'Gerencie seus locais',
      manage_your_transports: 'Gerencie seus transportes',
      manage_your_equipments: 'Gerencie seus equipamentos',
      manage_your_event_types: 'Gerencie seus tipos de eventos',
      manage_your_suppliers: 'Gerencie seus fornecedores',
      manage_your_rooms: 'Gerencie seus quartos',
      advanced_filter: 'Filtro avançado',
      application_numbers: 'Números de aplicação',
      start_time: 'Hora de início',
      duration: 'Duração',
      event_updated: 'Evento atualizado',
      item_created: 'Item criado',
      item_updated: 'Item atualizado',
      error_update: 'Erro ao atualizar',
      confirm_delete_message: 'Tem certeza que deseja excluir?',
      confirm_delete: 'Confirmar exclusão',
      cancel: 'Cancelar',
      event_deleted: 'Evento excluído',
      error: 'Erro',
      create_event: 'Criar evento',
      update_event: 'Atualizar evento',
      payment: 'Pagamento',
      earnings: 'Ganhos',
      in: 'em',
      events_happened_in: 'eventos ocorridos em',
      set_default_app: 'Primária',
      invite_user: 'Convidar Usuário',
      invite: 'Convidar',
      application_name: 'Nome da Aplicação',
      email: 'Email'
    }
  },
  gb: {
    message: {
      "app_status": {
        "pending": "Pending",
        "active": "Active",
        "inactive": "Inactive",
        "blocked": "Blocked"
      },
      add: 'Add',
      name: 'Name',
      close: 'Close',
      back: 'Back',
      back_to_list: 'Back to list',
      create: 'Create',
      update: 'Update',
      delete: 'Delete',
      copy: 'Copy',
      hello: 'Hello',
      today_events: 'Today events',
      welcome: 'Welcome',
      goodbye: 'Goodbye',
      thank_you: 'Thank you',
      yes: 'Yes',
      no: 'No',
      please: 'Please',
      sorry: 'Sorry',
      excuse_me: 'Excuse me',
      good_morning: 'Good morning',
      good_afternoon: 'Good afternoon',
      good_night: 'Good night',
      search: 'Search',
      comments: 'Comments',
      update_comments: 'Update comments',
      comments_updated: 'Comments updated',
      instructors: 'Instructors',
      events: 'Events',
      event_types: 'Event types',
      copy_event: 'Copy event',
      customers: 'Customers',
      staff: 'Staff',
      create_staff: 'Create Staff',
      update_staff: 'Update Staff',
      staffs: 'Staffs',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      fourdays: '4 Days',
      be_prepared: 'Be prepared',
      event_at: 'Event at',
      notes: 'Notes',
      boards: 'Boards',
      board: 'Board',
      wetsuits: 'Wetsuits',
      wetsuit: 'Wetsuit',
      country: 'Country',
      select_country: 'Select the country',
      packages: 'Packages',
      services: 'Services',
      place: 'Place',
      places: 'Places',
      staffs_payments: 'Staffs payments',
      supplier: 'Supplier',
      create_supplier: 'Create Supplier',
      update_supplier: 'Update Supplier',
      suppliers: 'Suppliers',
      equipment: 'Equipment',
      create_equipment: 'Create Equipment',
      update_equipment: 'Update Equipment',
      equipments: 'Equipments',
      transports: 'Transports',
      transport: 'Transport',
      rooms: 'Rooms',
      options: 'Options',
      quantity: 'Quantity',
      type: 'Type',
      manage_your_packages: 'Manage your packages',
      manage_your_services: 'Manage your services',
      manage_your_places: 'Manage your places',
      manage_your_transports: 'Manage your transports',
      manage_your_equipments: 'Manage your equipments',
      manage_your_event_types: 'Manage your event types',
      manage_your_suppliers: 'Manage your suppliers',
      manage_your_rooms: 'Manage your rooms',
      advanced_filter: 'Advanced filter',
      application_numbers: 'Application numbers',
      start_time: 'Start time',
      duration: 'Duration',
      event_updated: 'Event updated',
      item_created: 'Item created',
      item_updated: 'Item updated',
      error_update: 'Error update',
      confirm_delete_message: 'Are you sure you want to delete?',
      confirm_delete: 'Confirm delete',
      cancel: 'Cancel',
      event_deleted: 'Event deleted',
      error: 'Error',
      create_event: 'Create event',
      update_event: 'Update event',
      payment: 'Payment',
      earnings: 'Earnings',
      in: 'in',
      events_happened_in: 'events happened in',
      set_default_app: 'Primary',
      invite_user: 'Invite User',
      invite: 'Invite',
      application_name: 'Application Name',
      email: 'Email'
    }
  },
  fr: {
    message: {
      "app_status": {
        "pending": "En attente",
        "active": "Actif",
        "inactive": "Inactif",
        "blocked": "Bloqué"
      },
      add: 'Ajouter',
      name: 'Nom',
      close: 'Fermer',
      back: 'Retour',
      back_to_list: 'Retour à la liste',
      create: 'Créer',
      update: 'Mettre à jour',
      delete: 'Supprimer',
      copy: 'Copier',
      hello: 'Bonjour',
      today_events: "Événements d'aujourd'hui",
      welcome: 'Bienvenue',
      goodbye: 'Au revoir',
      thank_you: 'Merci',
      yes: 'Oui',
      no: 'Non',
      please: 'S\'il vous plaît',
      sorry: 'Désolé',
      excuse_me: 'Excusez-moi',
      good_morning: 'Bonjour',
      good_afternoon: 'Bon après-midi',
      good_night: 'Bonne nuit',
      search: 'Chercher',
      comments: 'Commentaires',
      update_comments: 'Mettre à jour les commentaires',
      comments_updated: 'Commentaires mis à jour',
      instructors: 'Instructeurs',
      events: 'Événements',
      event_types: 'Types d\'événements',
      copy_event: 'Copier événement',
      customers: 'Clients',
      staff: 'Personnel',
      create_staff: 'Créer Personnel',
      update_staff: 'Mettre à jour Personnel',
      staffs: 'Personnels',
      day: 'Jour',
      week: 'Semaine',
      month: 'Mois',
      year: 'Année',
      fourdays: '4 Jours',
      be_prepared: 'Soyez prêt',
      event_at: 'Événement à',
      notes: 'Remarques',
      boards: 'Planches',
      board: 'Planche',
      wetsuits: 'Combinaisons',
      wetsuit: 'Combinaison',
      country: 'Pays',
      select_country: 'Sélectionnez le pays',
      packages: 'Forfaits',
      services: 'Services',
      place: 'Lieu',
      places: 'Lieux',
      staffs_payments: 'Paiements du personnel',
      supplier: 'Fournisseur',
      create_supplier: 'Créer Fournisseur',
      update_supplier: 'Mettre à jour Fournisseur',
      suppliers: 'Fournisseurs',
      equipment: 'Équipement',
      create_equipment: 'Créer Équipement',
      update_equipment: 'Mettre à jour Équipement',
      equipments: 'Équipements',
      transports: 'Transports',
      transport: 'Transport',
      rooms: 'Chambres',
      options: 'Options',
      quantity: 'Quantité',
      type: 'Type',
      manage_your_packages: 'Gérez vos forfaits',
      manage_your_services: 'Gérez vos services',
      manage_your_places: 'Gérez vos lieux',
      manage_your_transports: 'Gérez vos transports',
      manage_your_equipments: 'Gérez vos équipements',
      manage_your_event_types: 'Gérez vos types d\'événements',
      manage_your_suppliers: 'Gérez vos fournisseurs',
      manage_your_rooms: 'Gérez vos chambres',
      advanced_filter: 'Filtre avancé',
      application_numbers: 'Nombres d\'application',
      start_time: 'Heure de début',
      duration: 'Durée',
      event_updated: 'Événement mis à jour',
      item_created: 'Article créé',
      item_updated: 'Article mis à jour',
      error_update: 'Erreur de mise à jour',
      confirm_delete_message: 'Êtes-vous sûr de vouloir supprimer?',
      confirm_delete: 'Confirmer la suppression',
      cancel: 'Annuler',
      event_deleted: 'Événement supprimé',
      error: 'Erreur',
      create_event: 'Créer événement',
      update_event: 'Mettre à jour événement',
      payment: 'Paiement',
      earnings: 'Gains',
      in: 'dans',
      events_happened_in: 'événements se sont produits dans',
      set_default_app: 'Défaut',
      invite_user: 'Inviter un utilisateur',
      invite: 'Inviter',
      application_name: 'Nom de l\'application',
      email: 'Email'
    }
  },
  de: {
    message: {
      "app_status": {
        "pending": "Ausstehend",
        "active": "Aktiv",
        "inactive": "Inaktiv",
        "blocked": "Blockiert"
      },
      add: 'Hinzufügen',
      name: 'Name',
      close: 'Schließen',
      back: 'Zurück',
      back_to_list: 'Zurück zur Liste',
      create: 'Erstellen',
      update: 'Aktualisieren',
      delete: 'Löschen',
      copy: 'Kopieren',
      hello: 'Hallo',
      today_events: 'Heutige Veranstaltungen',
      welcome: 'Willkommen',
      goodbye: 'Auf Wiedersehen',
      thank_you: 'Danke',
      yes: 'Ja',
      no: 'Nein',
      please: 'Bitte',
      sorry: 'Entschuldigung',
      excuse_me: 'Entschuldigen Sie',
      good_morning: 'Guten Morgen',
      good_afternoon: 'Guten Tag',
      good_night: 'Gute Nacht',
      search: 'Suchen',
      comments: 'Komment',
      update_comments: 'Komment aktualisieren',
      comments_updated: 'Komment aktualisiert',
      instructors: 'Instruktoren',
      events: 'Veranstaltungen',
      event_types: 'Veranstaltungstypen',
      copy_event: 'Veranstaltung kopieren',
      customers: 'Kunden',
      staff: 'Mitarbeiter',
      create_staff: 'Mitarbeiter erstellen',
      update_staff: 'Mitarbeiter aktualisieren',
      staffs: 'Mitarbeiter',
      day: 'Tag',
      week: 'Woche',
      month: 'Monat',
      year: 'Jahr',
      fourdays: '4 Tage',
      be_prepared: 'Sei vorbereitet',
      event_at: 'Veranstaltung bei',
      notes: 'Notizen',
      boards: 'Bretter',
      board: 'Brett',
      wetsuits: 'Neoprenanzüge',
      wetsuit: 'Neoprenanzug',
      country: 'Land',
      select_country: 'Land auswählen',
      packages: 'Pakete',
      services: 'Dienstleistungen',
      place: 'Ort',
      places: 'Orte',
      staffs_payments: 'Mitarbeiterzahlungen',
      supplier: 'Lieferant',
      create_supplier: 'Lieferant erstellen',
      update_supplier: 'Lieferant aktualisieren',
      suppliers: 'Lieferanten',
      equipment: 'Ausrüstung',
      create_equipment: 'Ausrüstung erstellen',
      update_equipment: 'Ausrüstung aktualisieren',
      equipments: 'Ausrüstungen',
      transports: 'Transporte',
      transport: 'Transport',
      rooms: 'Zimmer',
      options: 'Optionen',
      quantity: 'Menge',
      type: 'Typ',
      manage_your_packages: 'Verwalten Sie Ihre Pakete',
      manage_your_services: 'Verwalten Sie Ihre Dienste',
      manage_your_places: 'Verwalten Sie Ihre Orte',
      manage_your_transports: 'Verwalten Sie Ihre Transporte',
      manage_your_equipments: 'Verwalten Sie Ihre Ausrüstung',
      manage_your_event_types: 'Verwalten Sie Ihre Veranstaltungstypen',
      manage_your_suppliers: 'Verwalten Sie Ihre Lieferanten',
      manage_your_rooms: 'Verwalten Sie Ihre Zimmer',
      advanced_filter: 'Erweiterter Filter',
      application_numbers: 'Antragsnummern',
      start_time: 'Startzeit',
      duration: 'Dauer',
      event_updated: 'Veranstaltung aktualisiert',
      item_created: 'Artikel erstellt',
      item_updated: 'Artikel aktualisiert',
      error_update: 'Fehler beim Aktualisieren',
      confirm_delete_message: 'Möchten Sie wirklich löschen?',
      confirm_delete: 'Löschen bestätigen',
      cancel: 'Stornieren',
      event_deleted: 'Veranstaltung gelöscht',
      error: 'Fehler',
      create_event: 'Veranstaltung erstellen',
      update_event: 'Veranstaltung aktualisieren',
      payment: 'Zahlung',
      earnings: 'Einnahmen',
      in: 'in',
      events_happened_in: 'ereignisse ereigneten sich in',
      set_default_app: 'Standard',
      invite_user: 'Benutzer einladen',
      invite: 'Einladen',
      application_name: 'Anwendungsname',
      email: 'Email'
    }
  },
  es: {
    message: {
      "app_status": {
        "pending": "Pendiente",
        "active": "Activo",
        "inactive": "Inactivo",
        "blocked": "Bloqueado"
      },
      add: 'Añadir',
      name: 'Nombre',
      close: 'Cerrar',
      back: 'Volver',
      back_to_list: 'Volver a la lista',
      create: 'Crear',
      update: 'Actualizar', 
      delete: 'Eliminar',
      copy: 'Copiar',
      hello: 'Hola',
      today_events: 'Eventos de hoy',
      welcome: 'Bienvenido',
      goodbye: 'Adiós',
      thank_you: 'Gracias',
      yes: 'Sí',
      no: 'No',
      please: 'Por favor',
      sorry: 'Lo siento',
      excuse_me: 'Perdón',
      good_morning: 'Buenos días',
      good_afternoon: 'Buenas tardes',
      good_night: 'Buenas noches',
      search: 'Buscar',
      comments: 'Comentarios',
      update_comments: 'Actualizar comentarios',
      comments_updated: 'Comentarios actualizados',
      instructors: 'Instructores',
      events: 'Eventos',
      event_types: 'Tipos de eventos',
      copy_event: 'Copiar evento',
      customers: 'Clientes',
      staff: 'Personal',
      create_staff: 'Crear Personal',
      update_staff: 'Actualizar Personal',
      staffs: 'Personal',
      day: 'Día',
      week: 'Semana',
      month: 'Mes',
      year: 'Año',
      fourdays: '4 Días',
      be_prepared: 'Estar preparado',
      event_at: 'Evento en',
      notes: 'Notas',
      boards: 'Tablas',
      board: 'Tabla',
      wetsuits: 'Trajes de neopreno',
      wetsuit: 'Traje de neopreno',
      country: 'País',
      select_country: 'Selecciona el país',
      packages: 'Paquetes',
      services: 'Servicios',
      place: 'Lugar',
      places: 'Lugares',
      staffs_payments: 'Pagos de personal',
      supplier: 'Proveedor',
      create_supplier: 'Crear Proveedor',
      update_supplier: 'Actualizar Proveedor',
      suppliers: 'Proveedores',
      equipment: 'Equipo',
      create_equipment: 'Crear Equipo',
      update_equipment: 'Actualizar Equipo',
      equipments: 'Equipos',
      transports: 'Transportes',
      transport: 'Transporte',
      rooms: 'Habitaciones',
      options: 'Opciones',
      quantity: 'Cantidad',
      type: 'Tipo',
      manage_your_packages: 'Administra tus paquetes',
      manage_your_services: 'Administra tus servicios',
      manage_your_places: 'Administra tus lugares',
      manage_your_transports: 'Administra tus transportes',
      manage_your_equipments: 'Administra tus equipos',
      manage_your_event_types: 'Administra tus tipos de eventos',
      manage_your_suppliers: 'Administra tus proveedores',
      manage_your_rooms: 'Administra tus habitaciones',
      advanced_filter: 'Filtro avanzado',
      application_numbers: 'Números de aplicación',
      start_time: 'Hora de inicio',
      duration: 'Duración',
      event_updated: 'Evento actualizado',
      item_created: 'Artículo creado',
      item_updated: 'Artículo actualizado',
      error_update: 'Error al actualizar',
      confirm_delete_message: '¿Estás seguro de que quieres eliminar?',
      confirm_delete: 'Confirmar eliminación',
      cancel: 'Cancelar',
      event_deleted: 'Evento eliminado',
      error: 'Error',
      create_event: 'Crear evento',
      update_event: 'Actualizar evento',
      payment: 'Pago',
      earnings: 'Ganancias',
      in: 'en',
      events_happened_in: 'eventos ocurrieron en',
      set_default_app: 'Predeterminado',
      invite_user: 'Invitar Usuario',
      invite: 'Invitar',
      application_name: 'Nombre de la Aplicación',
      email: 'Correo Electrónico'
    }
  }
}

export default new VueI18n({
  locale: 'gb', // set locale
  fallbackLocale: 'gb', // set fallback locale
  messages: messages
})