<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon left>mdi-room-service-outline</v-icon> Service Types
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        :label="$t('message.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="columns"
        :items="rows"
        :search="search"
        :page.sync="page"
        hide-default-footer
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
      >
      </v-data-table>
      <div class="text-center pt-2" style="">
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'

export default {
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      loadingUpdate: false,
      search: '',
      rows: [],
      columns: [
        { text: 'Type', align: 'left', value: 'Type' },
        { text: this.$t('message.options'), sortable: false, value: 'options', width: '250px' }
      ],
      rowsPerPageItems: [5, 10, 25, 50],
      isNew: false,
      apiVersion: 'v1',
    }
  },
  mounted: function () {
    this.getAll()
    bus.$on('refreshServiceTypeList', () => { this.getAll() })
  },
  beforeDestroy () {
    bus.$off('refreshServiceTypeList', null)
  },
  methods: {
    getAll: function () {
      this.loading = true
      httpClient
        .get('/package/api/v1/services/types')
        //.get('http://localhost:13004/api/v1/services/types')
        .then((r) => {
          this.loading = false
          this.rows = []
          if (r.data !== null) {
            this.rows = r.data
          }
        })
        .catch(() => { 
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting services'})
        })
    }
  }
}
</script>
