var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" nmSmart users "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":_vm.$t('message.search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(false)?_c('v-card-text',[_c('v-row',_vm._l((_vm.rows),function(user){return _c('v-col',{key:user.Email,attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(_vm._s(user.Email))]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(_vm._s(user.UpdatedDate))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(user.Name)+" "+_vm._s(user.Surname))])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""}},[_vm._v(_vm._s(user.StaffType))]),_c('v-btn',{attrs:{"text":""}},[_vm._v("Button")])],1)],1)],1)}),1)],1):_vm._e(),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.rows,"sort-by":['UpdatedDate'],"sort-desc":[true],"search":_vm.search,"items-per-page":5,"footer-props":{
        itemsPerPageOptions: _vm.rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }},scopedSlots:_vm._u([{key:"item.UpdatedDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.UpdatedDate))+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }